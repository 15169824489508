import ComponentUploadMedia                                 from "$components/ComponentUploadMedia";
import ElementButton                                        from "$elements/ElementButton";
import ElementCheckbox                                      from "$elements/ElementCheckbox";
import ElementDialog                                        from "$elements/ElementDialog";
import ElementInputPhone                                    from "$elements/ElementInputPhone";
import ElementInputText                                     from "$elements/ElementInputText";
import ElementServiceOptions                                from "$elements/ElementServiceOptions";
import ElementTextarea                                      from "$elements/ElementTextarea";
import IPhoto                                               from "$models/IPhoto";
import IServiceOption                                       from "$models/IServiceOption";
import shadows                                              from "$scss/_shadows.module.scss";
import {useGetServicesQuery, useLazyGetServiceOptionsQuery} from "$services/reference";
import {useSaveTicketMutation}                              from "$services/tickets";
import {formatDate, formatMoney}                            from "$utils/formatters";
import * as React                                           from "react";
import {useEffect, useState}                                from "react";
import {useTranslation}                                     from "react-i18next";
import styled                                               from "styled-components";
import IAddress                                             from "$models/IAddress";
import ElementMap                                           from "$elements/ElementMap";
import colors                                               from "$scss/_colors.module.scss";
import scssVars                                             from "$scss/_variables.module.scss"
import {HeaderH1}                                           from "$elements/styled";
import ElementServiceOptionItems                            from "$pages/PageHome/elements/ElementServiceOptionItems";
import {IGoodItem}                                          from "$models/INewTicket";


// <editor-fold desc="styled elements">
const StocksHolder = styled.div`
  background-color: ${colors.limegreenLighten3};
  border-radius: ${scssVars.borderRadius};
`

const FormWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 3.2rem 0;
  grid-row: FormTop / FormBottom;
`

const EmptyPagePlug = styled.div`
  height: calc(100% - 11.19rem);
`

const ScrollingContainer = styled.div`
  overflow: auto;
`

const ControlWrapper = styled.div`
  background: #fff;
  padding: .8rem 15rem;
  grid-row: ControlTop / ControlBottom;
  box-shadow: ${shadows.lg};
`

const ToggleHeader = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 1.2rem;
  padding: .5rem 2rem;
`

const PricesTable = styled.table`
  tr {
    border: none;

    &:hover {
      background: #fff;
    }

    td {
      padding: .4rem !important;
    }
  }
`

const FormGrid = styled.div`
  display: grid;
  grid-template-rows:[FormTop] auto [FormBottom ControlTop] 12rem [ControlBottom];
  height: 100%;
`

const CheckWrapper = styled.div`
  border-radius: 1.2rem;
  background: #fff;
  padding: 2.4rem;
`

const SelectTag = styled.div`
  position: relative;
  border-radius: 1.2rem;

  &:after {
    position: absolute;
    content: 'keyboard_arrow_down';
    font-family: "Material Icons Outlined";
    top: 1.7rem;
    line-height: 1;
    right: 1.7rem;
    font-size: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    color: #A1A1A1;
    pointer-events: none;
  }
`
const StyledSelect = styled.select`
  width: 100%;
  outline: none;
  padding: 1.6rem 4.4rem 1.6rem 1.6rem;
  border-radius: 1.2rem;
  line-height: 2.4rem;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
`

//</editor-fold>

enum EToggleListType {
  COMMON = "commonToggle",
  ADDITIONAL = "additionalToggle",
  SPECIAL = "specialToggle",
}

export interface IGoodItemWithPrice extends IGoodItem {
  price: number,
}

const i18n_prefix = 'page.home.newTicket.';
export default function NewTicket() {
  const {t} = useTranslation();
  const {data: services, isLoading: fetchingServices} = useGetServicesQuery();
  const [fetchOptions, {data: options, isLoading: fetchingOptions}] = useLazyGetServiceOptionsQuery();
  const [saveTicket, {
    data:      invoiceUrl,
    isSuccess: ticketSaved,
    isLoading: ticketIsSaving,
  }] = useSaveTicketMutation();

  const [service, setService] = useState<number>();
  const [cityId, setCityId] = useState<number>();
  const [selectedCommonOptions, setSelectedCommonOptions] = useState<{ [key: string]: { count: number, price: number, id: string, items?: IGoodItemWithPrice[] } }>({});
  const [commonOptionsTotal, setCommonOptionsTotal] = useState(0);
  const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState<{ [key: string]: { count: number, price: number, id: string, items?: IGoodItemWithPrice[] } }>({});
  const [additionalOptionsTotal, setAdditionalOptionsTotal] = useState(0);
  const [address, setAddress] = useState<IAddress>();
  const [comment, setComment] = useState('');
  const [photos, setPhotos] = useState<IPhoto[]>([]);
  const [ticketCreated, setTicketCreated] = useState(false);
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');
  const [clientName, setClientName] = useState('');
  const [checks, setChecks] = useState<Set<number>>(new Set());
  const [multiplier, setMultiplier] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [{commonToggle, additionalToggle, specialToggle}, setToggle] = useState({
    commonToggle:     true,
    additionalToggle: true,
    specialToggle:    true,
  });
  const [goodsIds, setGoodsIds] = useState<number[]>([]);

  const handleSetCurrentGoodIds = (id: number, selected: boolean, item: IGoodItemWithPrice, optionId: string, optionType: string) => {
    if (!id) return;
    const options = optionType === 'common' ? {...selectedCommonOptions} : {...selectedAdditionalOptions};
    const updateSelectedOptions = optionType === 'common' ? setSelectedCommonOptions : setSelectedAdditionalOptions;
    const items = new Set(options[optionId].items);
    const itemsArray = [...items];
    const newIds = new Set(goodsIds);
    if (selected) {
      newIds.add(id);
      items.add(item);
    } else {
      newIds.delete(id);
      const itemToRemove = itemsArray.find(item => item.id === id);
      if (itemToRemove) {
        items.delete(itemToRemove);
      }
    }
    options[optionId] = {...options[optionId], items: [...items]};
    setGoodsIds([...newIds]);
    updateSelectedOptions(options)

    if (options[optionId]) {
      const updatedOptions = {...options};
      updateSelectedOptions(updatedOptions);
      updateTotals(Object.values(updatedOptions), optionType === 'common');
    }
  }

  const toggleCheck = (value: number, checked: boolean) => {
    if (checked) {
      checks.add(value);
    } else {
      checks.delete(value);
    }

    let newMultiplier = 1;
    const selectedChecks = options?.check.filter(c => checks.has(c.id as number));
    selectedChecks?.forEach(c => {
      if (c.multiplier) newMultiplier += c.multiplier
    });

    setMultiplier(newMultiplier);

    setChecks(new Set(checks))
  }

  useEffect(() => {
    if (service) {
      fetchOptions(service)
    }
  }, [service])

  useEffect(() => {
    if (ticketSaved) {
      setTicketCreated(true);
    }
  }, [ticketSaved])

  const reset = () => {
    if (services && services.length) {
      setService(services[0].id);
      fetchOptions(services[0].id);
    }
    setSelectedCommonOptions({});
    setCommonOptionsTotal(0);
    setAdditionalOptionsTotal(0);
    setSelectedAdditionalOptions({});
    setChecks(new Set());
    setPhoneOne('');
    setPhoneTwo('');
    setAddress({} as IAddress);
    setClientName('');
    setComment('');
    setPhotos([]);
  }

  const currentServicePromotion = services?.find(item => item.id === service)?.promotion;

  const save = () => {
    const options = [...Object.values(selectedCommonOptions), ...Object.values(selectedAdditionalOptions)]

    saveTicket({
      service_id: service as number,
      options:    options.map(option => ({
        value: +option.count.toFixed(1),
        id:    option.id,
        items: !option.items?.length ? undefined : option.items,
      })),
      phones:     [phoneOne, phoneTwo],
      address,
      name:       clientName,
      city_id:    cityId,
      photos:     photos.map(photo => photo.digest),
      comment,
      checks:     [...checks],
    });
  }

  const updateService = (value: number | string) => {
    if (!value) return;
    const serviceId = parseInt(`${value}`);
    setSelectedCommonOptions({});
    setSelectedAdditionalOptions({});
    setService(serviceId);
    fetchOptions(serviceId);

    updateTotals([], true);
    updateTotals([], false);
  }

  const updateTotals = (options: { count: number, price: number, items?: { value: number, id: number, price: number }[] }[], common: boolean) => {
    const updateTotal = common ? setCommonOptionsTotal : setAdditionalOptionsTotal;
    const totalPrice = options.map(option => {
      let total = 0;
      if (option.items && option.items.length > 0) {
        total += option.items.reduce((acc, item) => acc + (item.price * item.value), 0);
      }
      total += option.count * option.price;
      return total;
    }).reduce((previousValue, currentValue) => Math.round(currentValue) + Math.round(previousValue), 0);
    updateTotal(totalPrice);
  };

  const updateSelectedOptionItems = (option: IServiceOption, count: number, itemId: number, common: boolean) => {
    const selectedOptions = common ? selectedCommonOptions : selectedAdditionalOptions;
    const updateSelectedOptions = common ? setSelectedCommonOptions : setSelectedAdditionalOptions;
    if (selectedOptions[option.id]) {
      const updateOptions = {...selectedOptions};
      const updatedItems = updateOptions[option.id].items?.map(item => item.id === itemId ? {
        ...item,
        value: count,
      } : item);
      if (updatedItems) {
        updateOptions[option.id].items = updatedItems;
        updateSelectedOptions(updateOptions);
        updateTotals(Object.values(updateOptions), common);
      }
    }
  }

  const updateSelectedOptions = (option: IServiceOption, count: number, common = true) => {
    const selectedOptions = common ? selectedCommonOptions : selectedAdditionalOptions;
    const updateSelectedOptions = common ? setSelectedCommonOptions : setSelectedAdditionalOptions;
    if (selectedOptions[option.id]) {
      const updatedOptions = {...selectedOptions};
      updatedOptions[option.id] = {count, id: option.id, price: option.price, items: updatedOptions[option.id].items};
      updateSelectedOptions(updatedOptions);
      updateTotals(Object.values(updatedOptions), common);
    }
  }

  const toggleSelectedOption = (option: IServiceOption, selected: boolean, common = true) => {
    const selectedOptions = common ? selectedCommonOptions : selectedAdditionalOptions;
    const updateSelectedOptions = common ? setSelectedCommonOptions : setSelectedAdditionalOptions;
    if (!selected && selectedOptions[option.id]) {
      const {[option.id]: _removing, ...others} = selectedOptions;
      updateSelectedOptions(others);
      updateTotals(Object.values(others), common);

      const selectedOptionItems = selectedOptions[option.id].items?.map(item => item.id);
      const newGoodIds = new Set(goodsIds);
      selectedOptionItems?.forEach(item => newGoodIds.delete(item));

      setGoodsIds([...newGoodIds]);

    }
    if (selected && !selectedOptions[option.id]) {
      const options = {...selectedOptions};
      options[option.id] = {count: option.min, id: option.id, price: option.price};
      updateSelectedOptions(options);

      updateTotals(Object.values(options), common);
    }
  }

  const handleSearchValueChange = (value: string | number) => {
    setSearchValue(`${value}`);
  };

  const handleToggleClick = (key: EToggleListType) => {
    setToggle(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const conditionalOptionsCheck = !options?.check.find(f => f.is_active) && !options?.common.find(f => f.is_active) && !options?.additional.find(f => f.is_active);

  return <FormGrid>
    <ScrollingContainer className="styled-scrollbar">
      <FormWrapper>
        <div className="pos-r mgb-5">
          <SelectTag>
            <StyledSelect
              required
              value={service ?? ''}
              onChange={e => updateService(e.target.value)}
              className={fetchingServices ? 'horizontal-progress-bar active' : ''}
            >
              <option value="" disabled>{t(`${i18n_prefix}placeholders.category`)}</option>
              {services?.map(item => item.is_active && <option
                key={`service-${item.id}`}
                value={item.id}
              >{item.text}</option>)}
            </StyledSelect>
            {!conditionalOptionsCheck && <ElementInputText
              label={t(`${i18n_prefix}labels.search`)}
              value={searchValue}
              onUpdateValue={handleSearchValueChange}
              className="mgt-2"
            />}
          </SelectTag>
        </div>

        {currentServicePromotion && <StocksHolder className="flex dir-column centered pda-2 mgb-3">
          <HeaderH1>{t(`${i18n_prefix}promotion.header`, {discount: currentServicePromotion?.discount})}</HeaderH1>
          <p>{currentServicePromotion?.text}</p>
          <span>{t(`${i18n_prefix}promotion.valid`, {
            from: formatDate(new Date(currentServicePromotion?.start_at ?? '')),
            to:   formatDate(new Date(currentServicePromotion?.end_at ?? '')),
          })}</span>
        </StocksHolder>}

        {conditionalOptionsCheck ? <EmptyPagePlug className="flex centered">
            <span className="text fs-huge">{t(`${i18n_prefix}empty_page_plug`)}</span>
          </EmptyPagePlug> :
          <>{fetchingOptions ? <div className="horizontal-progress active" /> : <>
            {options && (options.check.find(option => option.is_active) || options.canceling.find(option => option.is_active)) &&
              <div className="mgb-3">
                <ToggleHeader
                  onClick={() => handleToggleClick(EToggleListType.SPECIAL)}
                  className="flex text fs-large fw-medium mgb-1-2 v-centered"
                >
                  <div>{t(`${i18n_prefix}labels.special`)}</div>
                  <span className={"material-icons"}>{specialToggle ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                </ToggleHeader>
                {specialToggle && options && <>
                  <>
                    {options.canceling.filter(option => option.title.toLowerCase().includes(searchValue) && option.is_active).map(item =>
                      <div
                        className="mgb-1"
                        key={`${service}-${item.id}`}
                      >
                        <ElementServiceOptions
                          isSpecialOption={!item.step}
                          option={item}
                          selected={selectedCommonOptions[item.id] != null}
                          count={selectedCommonOptions[item.id]?.count ?? 1}
                          onOptionSelectChange={(selected) => toggleSelectedOption(item, selected)}
                          onCountChange={(count) => updateSelectedOptions(item, count)}
                        />
                      </div>)}
                  </>
                  <div className="mgb-3">
                    {options.check.filter(option => option.title.toLowerCase().includes(searchValue) && option.is_active).map((check, index) =>
                      <CheckWrapper
                        className="mgb-1"
                        key={`${check.name}-${index}`}
                      >
                        <ElementCheckbox
                          label={check.title}
                          checked={checks.has(check.id as number)}
                          onValueChanged={(_, selected) => toggleCheck(check.id as number, selected)}
                        />
                      </CheckWrapper>)}
                  </div>
                </>}
              </div>}
            {options?.common && options?.common.find(option => option.is_active) &&
              <div className="mgb-3">
                <ToggleHeader
                  onClick={() => handleToggleClick(EToggleListType.COMMON)}
                  className="flex text fs-large fw-medium mgb-1-2 v-centered"
                >
                  <div>{t(`${i18n_prefix}labels.services`)}</div>
                  <span className={"material-icons"}>{commonToggle ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                </ToggleHeader>
                {commonToggle && options.common.filter(option => option.title.toLowerCase().includes(searchValue)).map(option =>
                  <div
                    className="mgb-1"
                    key={`${service}-${option.id}`}
                  >
                    <ElementServiceOptions
                      option={option}
                      selected={selectedCommonOptions[option.id] != null}
                      count={selectedCommonOptions[option.id]?.count}
                      onOptionSelectChange={(selected) => toggleSelectedOption(option, selected)}
                      onCountChange={(count) => updateSelectedOptions(option, count)}
                    >
                      {!option?.items?.length ? null : <ElementServiceOptionItems
                        onSetGoodsIds={handleSetCurrentGoodIds}
                        onCountChange={(count, itemId: number) => updateSelectedOptionItems(option, count, itemId, true)}
                        currentGoods={goodsIds}
                        items={option.items}
                        optionId={option.id}
                        optionType="common"
                        disabled={!selectedCommonOptions.hasOwnProperty(option.id)}
                      />}
                    </ElementServiceOptions>
                  </div>)}
              </div>}
            {options?.additional && options?.additional.find(option => option.is_active) &&
              <div className="mgb-3">
                <ToggleHeader
                  onClick={() => handleToggleClick(EToggleListType.ADDITIONAL)}
                  className="flex text fs-large fw-medium mgb-1-2 v-centered"
                >
                  <div>{t(`${i18n_prefix}labels.additional_services`)}</div>
                  <span className={"material-icons"}>{additionalToggle ? 'arrow_drop_up' : 'arrow_drop_down'}</span>
                </ToggleHeader>
                {additionalToggle && options.additional.filter(option => option.title.toLowerCase().includes(searchValue)).map(option =>
                  <div
                    className="mgb-1"
                    key={`${service}-${option.id}`}
                  >
                    <ElementServiceOptions
                      option={option}
                      selected={selectedAdditionalOptions[option.id] != null}
                      count={selectedAdditionalOptions[option.id]?.count}
                      onOptionSelectChange={(selected) => toggleSelectedOption(option, selected, false)}
                      onCountChange={(count) => updateSelectedOptions(option, count, false)}
                    >
                      {!option?.items?.length ? null : <ElementServiceOptionItems
                        onSetGoodsIds={handleSetCurrentGoodIds}
                        currentGoods={goodsIds}
                        items={option.items}
                        optionId={option.id}
                        onCountChange={(count, itemId: number) => updateSelectedOptionItems(option, count, itemId, false)}
                        optionType="additional"
                        disabled={!selectedAdditionalOptions.hasOwnProperty(option.id)}
                      />}
                    </ElementServiceOptions>
                  </div>)}
              </div>}
          </>}</>}

        {conditionalOptionsCheck ? <div /> : <>
          <ElementInputText
            className="mgb-1"
            required
            label={t(`${i18n_prefix}labels.client_name`)}
            placeholder={t(`${i18n_prefix}placeholders.client_name`)}
            value={clientName}
            onUpdateValue={value => setClientName(value as string)}
          />
          <ElementInputPhone
            className="mgb-1"
            required
            label={t(`${i18n_prefix}labels.client_phone`)}
            placeholder={t(`${i18n_prefix}placeholders.client_phone`)}
            value={phoneOne}
            onUpdateValue={value => setPhoneOne(value as string)}
          />
          <ElementInputPhone
            className="mgb-5"
            placeholder={t(`${i18n_prefix}placeholders.client_phone`)}
            value={phoneTwo}
            onUpdateValue={value => setPhoneTwo(value as string)}
          />
          <ElementMap
            label={t(`${i18n_prefix}labels.client_address`)}
            className="mgb-5"
            updateAddressList={value => {
              setAddress({
                address:   value[0].address,
                latitude:  `${value[0].latitude}`,
                longitude: `${value[0].longitude}`,
              });
              setCityId(value[0].city_id);
            }}
          />
          <div className="mgb-5">
            <ComponentUploadMedia
              value={photos}
              onUpdateValue={(value) => setPhotos(value)}
            />
          </div>

          <ElementTextarea
            className="mgb-5"
            value={comment}
            onUpdateValue={(value) => setComment(value ?? '')}
            placeholder={t(`${i18n_prefix}placeholders.comment`)}
            label={t(`${i18n_prefix}labels.comment`)}
          /></>}
      </FormWrapper>
    </ScrollingContainer>
    {!conditionalOptionsCheck && <ControlWrapper>
      <div className="flex centered">
        <PricesTable className="text fs-small flex-item">
          <tbody>
          <tr>
            <td>
              {t(`${i18n_prefix}common_services`)}:
            </td>
            <td>{formatMoney(multiplier * commonOptionsTotal)}</td>
          </tr>
          <tr>
            <td>
              {t(`${i18n_prefix}additional_services`)}:
            </td>
            <td>{formatMoney(multiplier * additionalOptionsTotal)}</td>
          </tr>
          <tr>
            <td>
              <div className="text fw-bold">{t(`${i18n_prefix}total_to_pay`)}:</div>
            </td>
            <td>
              <div
                className="text fw-bold">{formatMoney(multiplier * (additionalOptionsTotal + commonOptionsTotal))}</div>
            </td>
          </tr>
          </tbody>
        </PricesTable>
        <ElementButton
          buttonWidth={'50rem'}
          className="mgx-a flex-item"
          disabled={ticketIsSaving}
          loading={ticketIsSaving}
          onClick={save}
        >{t(`${i18n_prefix}create`)}</ElementButton>
      </div>
    </ControlWrapper>}
    <ElementDialog
      isOpen={ticketCreated}
      persistent
      onClosed={() => {
        reset();
        setTicketCreated(false)
      }}
    >
      <div className="text-center">
        <div className="mgb-3">
          <img
            src="/img/image.svg"
            alt=""
            width="150"
            height="152"
          />
        </div>
        <div className="mgb-1 text fs-18 fw-semibold">{t(`${i18n_prefix}success_dialog.message`)}</div>
        <div
          className="mgb-3 text fs-small darkgray">{t(`${i18n_prefix}success_dialog.total`, {price: formatMoney(multiplier * (commonOptionsTotal + additionalOptionsTotal))})}</div>
        {invoiceUrl ? <ElementButton
          className="mgb-3"
          buttonWidth={'100%'}
          onClick={() => window.open(invoiceUrl, '_blank')}
        >{t(`${i18n_prefix}success_dialog.print_invoice`)}</ElementButton> : null}
        <ElementButton
          buttonWidth={'100%'}
          onClick={() => setTicketCreated(false)}
        >{t(`${i18n_prefix}success_dialog.create_new_ticket`)}</ElementButton>
      </div>
    </ElementDialog>
  </FormGrid>
}