import * as React                      from "react";
import {useState, MouseEvent}          from "react";
import styled                          from "styled-components";
import ElementInputText, {IInputProps} from "../ElementInputText";
import EMoleculeSize                   from "$types/EMoleculeSize";


const ToggleButton = styled.button`
  box-shadow: none;
  border: none;
  padding: 0;
  outline: none;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
`

interface IState {
  type?: string;
}

export default function ElementInputPassword({
  alignText = 'left',
  readonly,
  disabled,
  errorMessage,
  focus = false,
  hint,
  id,
  label,
  mask,
  placeholder,
  prefix,
  className,
  size = EMoleculeSize.Large,
  value,
  onUpdateValue,
  onChange,
  onKeyUp,
  onKeyDown,
  onKeyPress,
}: IInputProps) {

  const [state, setState] = useState<IState>({type: 'password'})

  const toggleInputType = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const type = state.type === 'text' ? 'password' : 'text';

    setState(prevState => ({...prevState, type}))
  }

  const suffix = <ToggleButton
    className="material-icons"
    onClick={toggleInputType}
  >{state.type === 'text' ? 'visibility_off' : 'visibility'}</ToggleButton>

  return <ElementInputText
    alignText={alignText}
    type={state.type}
    suffix={suffix}
    disabled={disabled}
    errorMessage={errorMessage}
    focus={focus}
    hint={hint}
    id={id}
    className={className}
    label={label}
    mask={mask}
    placeholder={placeholder}
    prefix={prefix}
    readonly={readonly}
    size={size}
    value={value}
    onUpdateValue={onUpdateValue}
    onChange={onChange}
    onKeyUp={onKeyUp}
    onKeyDown={onKeyDown}
    onKeyPress={onKeyPress}
  />
}
