import classNames from "classnames";
import * as React from "react";
import {useRef, useState} from "react";
import styled from "styled-components";
import IPoint from "$models/IPoint";

interface IProps {
  stickyFirstColumn?: boolean,
  children: React.ReactNode,
  disableDragScroll?: boolean,
  className?: string,
}

const TableWrapper = styled.div`
  max-width: 100%;

  &.draggable {
    overflow: auto;
    max-height: 100%;
    max-width: 100%;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;

    &:active {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grabbing;
    }
  }
`

const ScrollContainer = styled.div`
  &.draggable {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`

const StyledTable = styled.table`
  max-width: 100%;
  table-layout: fixed;
`

interface IState {
  mouseIsDown: boolean,
  didDrag: boolean,
  wasDragged: boolean,
  dragStart: IPoint,
  scroll: IPoint,
}

export default function ElementTable({
  className,
  children,
  disableDragScroll = false,
  stickyFirstColumn = false,
}: IProps) {
  const [{mouseIsDown, dragStart, scroll, wasDragged, didDrag}, setState] = useState<IState>({
    mouseIsDown: false,
    didDrag:     false,
    wasDragged:  false,
    dragStart:   {x: 0, y: 0},
    scroll:      {x: 0, y: 0},
  });
  const refRoot = useRef<HTMLDivElement>(null);
  const refTable = useRef<HTMLDivElement>(null);
  const mouseUp = () => {
    if (disableDragScroll) return;
    const newState: IState = {mouseIsDown: false, wasDragged, didDrag, dragStart, scroll};
    if (didDrag) {
      newState.wasDragged = true;
      newState.didDrag = false;
    }
    setState(prevState => ({...prevState, ...newState}))
  };
  const mouseDown = (e: React.MouseEvent<HTMLTableElement, MouseEvent>) => {
    if (disableDragScroll) return;
    if (!refRoot || !refRoot.current || !refTable || !refTable.current) return;
    const newState: IState = {mouseIsDown, didDrag, wasDragged, dragStart, scroll};
    newState.mouseIsDown = true;
    newState.dragStart.x = e.pageX - refRoot.current.offsetLeft;
    newState.dragStart.y = e.pageY - refRoot.current.offsetTop;
    newState.scroll.x = refTable.current.scrollLeft;
    newState.scroll.y = refTable.current.scrollTop;

    setState(prevState => ({...prevState, ...newState}));
  }
  const drag = (e: React.MouseEvent<HTMLTableElement, MouseEvent>) => {
    if (disableDragScroll) return;
    e.preventDefault();
    if (!mouseIsDown) {
      return;
    }
    if (!refRoot || !refRoot.current || !refTable || !refTable.current) return;
    setState(prevState => ({...prevState, didDrag: true}));
    const x = e.pageX - refRoot.current.offsetLeft;
    const y = e.pageY - refRoot.current.offsetTop;
    const walk_x = (x - dragStart.x) * 3; //scroll-fast
    const walk_y = (y - dragStart.y) * 3; //scroll-fast
    refTable.current.scrollLeft = scroll.x - walk_x;
    refTable.current.scrollTop = scroll.y - walk_y;
  }
  return <ScrollContainer
    ref={refRoot}
    className={classNames(className, {'draggable': !disableDragScroll})}
  >
    <TableWrapper
      onMouseDown={mouseDown}
      onMouseLeave={() => {
        setState(prevState => ({...prevState, mouseIsDown: false}))
      }}
      onMouseUp={mouseUp}
      onMouseMove={drag}
      className={classNames("styled-scrollbar", {'draggable': !disableDragScroll})}
      ref={refTable}
    >
      <StyledTable
        className={classNames({
          'sticky-column': stickyFirstColumn,
        })}
      >{children}</StyledTable>
    </TableWrapper>
  </ScrollContainer>
}
