import ElementInputBase          from "$elements/ElementInputBase";
import {ElementBaseInputText}    from "$elements/ElementInputText";
import EPosition                 from "$types/EPosition";
import IInputBasePropsWithEvents from "$models/IInputBasePropsWithEvents";
import * as React                from "react";
import {useEffect, useState}     from "react";
import Calendar                  from 'react-calendar';
import styled                    from "styled-components";

interface IProps extends IInputBasePropsWithEvents<string, HTMLInputElement> {
  minDate?: Date,
  maxDate?: Date,
  position?: EPosition,
  align?: EPosition,
}

interface IDatePickerWrapperProps {
  position?: EPosition,
  open?: boolean,
  align?: EPosition,
}

// <editor-fold desc="styled elements">
const DatePickerWrapper = styled.div`
  position: absolute;
  display: ${({open}: IDatePickerWrapperProps) => open ? 'block' : "none"};
  ${({position, align}: IDatePickerWrapperProps) => {
    let result = '';
    switch (position) {
      case EPosition.TOP:
        result = 'bottom: 100%;';
        break;
      case EPosition.LEFT:
        result = 'left: 100%;';
        break;
      case EPosition.RIGHT:
        result = 'right: 100%;';
        break;
      case EPosition.CENTER:
        result = 'top: 50%; left: 50%; transform: translate(-50%,-50%,0);';
        break;
      default:
        result = 'top: 100%';
    }
    switch (align) {
      case EPosition.TOP:
        if (position === EPosition.TOP || position === EPosition.BOTTOM) {
          result += 'left: -1.6rem;';
        } else {
          result += 'top: 0;';
        }
        break;
      case EPosition.LEFT:
        if (position === EPosition.RIGHT || position === EPosition.LEFT) {
          result += 'top: 50%; transform: translateY(-50%);';
        } else {
          result += 'left: -1.6rem;';
        }
        break;
      case EPosition.RIGHT:
        if (position === EPosition.RIGHT || position === EPosition.LEFT) {
          result += 'top: 50%; transform: translateY(-50%);';
        } else {
          result += 'right: -1.6rem;';
        }
        break;
      case EPosition.BOTTOM:
        if (position === EPosition.TOP || position === EPosition.BOTTOM) {
          result += 'left: -1.6rem;';
        } else {
          result += 'bottom: 0;';
        }
        break;
    }
    return result;
  }}
  z-index: 2;
  background-color: #fff;
`

// </editor-fold>

interface IState {
  value?: Date,
  focused: boolean,
}

const ElementDatePicker = ({
  disabled,
  id,
  error = false,
  placeholder,
  align = EPosition.LEFT,
  position = EPosition.BOTTOM,
  required = false,
  minDate,
  maxDate,
  value: propValue,
  onUpdateValue = () => {
  },
  ...props
}: IProps) => {
  const [{value, focused}, setState] = useState<IState>({
    value:   propValue ? new Date(propValue) : new Date(),
    focused: false,
  });

  useEffect(() => {
    const clickOutside = () => {
      setState(prevState => ({
        ...prevState,
        focused: false,
      }))
    };
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("click", clickOutside);
    }
  }, []);

  useEffect(() => {
    if (propValue) {
      const dPropValue = new Date(propValue);
      if (dPropValue.getTime() !== value?.getTime()) {
        setState(prevState => ({
          ...prevState,
          value: new Date(propValue),
        }))
      }
    }
  }, [propValue]);
  const dateFormat: Intl.DateTimeFormatOptions = {
    day:   "2-digit",
    month: "2-digit",
    year:  'numeric',
  };

  const openDatePicker = () => {
    setState(prevState => ({...prevState, focused: true}))
  }
  // const closeDatePicker = () => {
  //   setState(prevState => ({...prevState, focused: false}))
  // }
  const onChange = (val: Date) => {
    if (val && val.getTime() !== value?.getTime()) {
      setState(prevState => ({
        ...prevState,
        value:   val,
        focused: false,
      }));
      const day = val.getDate().toString().padStart(2, '0');
      const month = (val.getMonth() + 1).toString().padStart(2, '0');
      const year = val.getFullYear();
      onUpdateValue(`${year}-${month}-${day}`)
    }
  }
  return <div
    className="pos-r"
    onClick={(e) => e.stopPropagation()}
  >
    <ElementBaseInputText
      // @ts-ignore
      mask={'00.00.0000'}
      error={error}
      required={required}
      value={value ? value.toLocaleDateString('ru', dateFormat) : ''}
      onFocus={openDatePicker}
      {...{id, disabled, placeholder, ...props}}
    />
    <DatePickerWrapper
      open={focused}
      align={align}
      position={position}
    >
      <Calendar
        returnValue={"start"}
        onClickDay={onChange}
        defaultValue={value}
        maxDate={maxDate}
        minDate={minDate}
        locale={'ru-RU'}
      />
    </DatePickerWrapper>
  </div>
}

export default ElementInputBase<string, IProps>(ElementDatePicker);
