import TicketStateBackgroundColors from "$store/constants/TicketStateBackgroundColors";
import ETicketState                from "$types/ETicketState";
import * as React                  from "react";
import {useTranslation}            from "react-i18next";
import styled                      from "styled-components";

interface IProps {
  state: ETicketState,
}

const backgroundColors = TicketStateBackgroundColors;

// <editor-fold desc="styled elements">
const State = styled.span`
  display: inline-block;
  padding: .4rem .8rem;
  border-radius: .4rem;
  font-weight: 500;
  font-size: 1.2rem;
  background-color: ${({state}: IProps) => backgroundColors[state]};
`
// </editor-fold>

export default function ElementTicketState({state}: IProps) {
  const {t} = useTranslation();
  return <State id={'state'} state={state} className="text nowrap">{t(`common.ticket_state.${state}`)}</State>
}
