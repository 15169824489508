import ElementButton                        from "$elements/ElementButton";
import ElementCheckbox                      from "$elements/ElementCheckbox";
import IServiceOption                       from "$models/IServiceOption";
import EButtonColor                         from "$types/EButtonColor";
import EButtonSize                          from "$types/EButtonSize";
import EButtonVariant                       from "$types/EButtonVariant";
import {formatMoney}                        from "$utils/formatters";
import * as React                           from "react";
import {KeyboardEvent, useEffect, useState} from "react";
import styled                               from "styled-components";
import ElementInputText                     from "$elements/ElementInputText";
import EMoleculeSize                        from "$types/EMoleculeSize";

interface IProps {
  option: IServiceOption,
  onCountChange: (count: number) => void,
  onOptionSelectChange: (selected: boolean) => void,
  selected?: boolean,
  count?: number,
  isSpecialOption?: boolean,
  children?: React.ReactNode,
}

// <editor-fold desc="styled elements">

const OptionWrapper = styled.div<{ is_active: boolean }>`
  padding: 2.4rem;
  background: #FFFFFF;
  border-radius: 1.2rem;
  display: ${({is_active}: { is_active: boolean }) => is_active ? 'block' : 'none'};
`

const Input = styled(ElementInputText)`
  & input {
    text-align: center;
    width: auto;
  }
`

// </editor-fold>

function checkFractionalNumber(value: number) {
  if (!value) return;
  if (value === 0) {
    return '';
  }
  return value.toString().includes('.') ? value.toFixed(1) : value.toString();
}

export default function ElementServiceOptions({
  onCountChange,
  onOptionSelectChange,
  selected,
  count: propCount,
  option,
  isSpecialOption = false,
  children,
}: IProps) {
  const [optionSelected, setOptionSelected] = useState(selected ?? false);
  const [count, setCount] = useState(propCount ?? option.min);
  const inputValue = !optionSelected ? `${checkFractionalNumber(count)} ${option.suffix}` : checkFractionalNumber(count);
  useEffect(() => {
    typeof selected === "boolean" && setOptionSelected(selected);
  }, [selected]);
  useEffect(() => {
    typeof propCount === 'number' && setCount(propCount);
  }, [propCount]);
  const updateCount = (count: number) => {
    if (isNaN(count)) {
      setCount(0);
      onCountChange(0);
    } else {
      setCount(count);
      onCountChange(count);
    }
  }
  const updateOptionSelected = (selected: boolean) => {
    onOptionSelectChange(selected);
    setOptionSelected(selected);
  };
  const handleKeyFractionalValuerPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key.match(/^[a-zа-яё\s]+$/iu) || event.key === ',' || event.key === '/') {
      event.preventDefault();
    }
  };
  const handleInputFocus = () => {
    onCountChange(0);
  };
  const handleInputBlur = () => {
    if (count === 0 || count < option.min) {
      onCountChange(option.min);
    }
  };
  return <OptionWrapper is_active={option.is_active}>
    <ElementCheckbox
      label={option.title}
      className="mgb-2"
      checked={optionSelected}
      onValueChanged={(_value, selected) => updateOptionSelected(selected)}
    />
    <>
      <div className="flex v-centered">
        <div className="flex-item fixed-size">
          <div className="text fw-semibold fs-huge">{formatMoney(option.price)}</div>
        </div>
        {!isSpecialOption && <>
          <div className="flex-item fixed-size right">
            <ElementButton
              size={EButtonSize.MEDIUM}
              variant={EButtonVariant.OUTLINED}
              buttonColor={EButtonColor.INFO}
              disabled={!optionSelected || (option.min != null && count <= option.min)}
              icon={'remove'}
              onClick={() => updateCount(count - option.step)}
            />
          </div>
          <div className="flex-item fixed-size text-center">
            <Input
              error={count < option.min}
              disabled={!optionSelected}
              value={inputValue}
              onUpdateValue={value => updateCount(+value)}
              onKeyPress={handleKeyFractionalValuerPress}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              size={EMoleculeSize.Small}
              className="mgx-1"
            />
          </div>
          <div className="flex-item fixed-size">
            <ElementButton
              size={EButtonSize.MEDIUM}
              variant={EButtonVariant.OUTLINED}
              buttonColor={EButtonColor.INFO}
              icon={'add'}
              disabled={!optionSelected || (option.max != null && count >= option.max)}
              onClick={() => updateCount(count + option.step)}
            />
          </div>
        </>}
      </div>
      {children}
    </>
  </OptionWrapper>
}