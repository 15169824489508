import urls                                                                      from "$config/urls";
import ElementButton                                                             from "$elements/ElementButton";
import {ErrorMessage, GalleryCaptionWrapper, GalleryImageViewWrapper, Thumbnail} from "$elements/styled";
import IPhoto                                                                    from "$models/IPhoto";
import Uploader, {EUploadTarget}                                                 from "$plugins/Uploader";
import scssVars                                                                  from "$scss//_variables.module.scss";
import colors                                                                    from "$scss/_colors.module.scss";
import EButtonColor                                                              from "$types/EButtonColor";
import EButtonSize                                                               from "$types/EButtonSize";
import EUploadStatus                                                             from "$types/EUploadStatus";
import IUploadFile                                                               from "$models/IUploadFile";
import classNames                                                                from "classnames";
import * as React                                                                from "react";
import {useEffect, useRef, useState}                                             from "react";
import {useTranslation}                                                          from "react-i18next";
import {Gallery, Item}                                                           from "react-photoswipe-gallery";
import styled                                                                    from "styled-components";

interface IProps {
  value: string[] | IPhoto[],
  disabled?: boolean,
  type?: EUploadTarget,
  height?: string,
  limit?: number,
  disablePreviews?: boolean,
  width?: string,
  className?: string,
  errorMessage?: string,
  size?: number | string,
  onUpdateValue: (value: IPhoto[]) => void
}

interface IPreviewProps {
  size?: number | string,
  error?: boolean,
}

interface ICellProps {
  count: number,
  hasSize: boolean,
}

// <editor-fold desc="styled elements">
const Cell = styled.div`
  width: ${({hasSize, count}: ICellProps) => hasSize ? 'auto' : `${100 / count}%`};
  max-width: 30rem;
  max-height: 30rem;
  padding: 1.2rem;
  flex: 0 0 ${({hasSize, count}: ICellProps) => hasSize ? 'auto' : `${100 / count}%`};
`
const MediaWrapper = styled.div``

const PreviewWrapper = styled.div`
  position: relative;
  border-radius: ${scssVars.borderRadius};

  &:before {
    content: ${({error}: IPreviewProps) => error ? "''" : ''};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .3;
    border-radius: ${scssVars.borderRadius};
    background-color: ${colors.redLighten3};
  }
`

const Preview = styled.img`
  border-radius: ${scssVars.borderRadius};
  width: ${({size}: IPreviewProps) => size ? `${typeof size === 'string' ? size : `${size / 10}rem`}` : '10rem'};
  height: ${({size}: IPreviewProps) => size ? `${typeof size === 'string' ? size : `${size / 10}rem`}` : '10rem'};
  object-fit: cover;
  box-shadow: ${({error}: IPreviewProps) => error ? `0 0 5px 1px ${colors.red}` : 'none'};
`
const DropZoneButton = styled.button`
  cursor: pointer;
  border: 1px dashed ${colors.dodgerblue};
  width: ${({width}: { width?: string, height?: string }) => width ? width : "10rem"};
  height: ${({height}: { width?: string, height?: string }) => height ? height : "10rem"};
  background: ${colors.dodgerblueLighten5};
  box-shadow: none;
  outline: none;
  display: flex;
  color: ${colors.dodgerblue};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color ${scssVars.transitionDuration} ${scssVars.transitionCurve};

  &.dragged-over {
    background: ${colors.dodgerblueLighten4};
  }
`
const ThumbnailWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  button {
    position: absolute;
    border-radius: 50%;
    border: 2px solid #fff;
    right: -1rem;
    top: -1rem;
  }
`

const Row = styled.div`
`

// </editor-fold>

interface IState {
  value: IPhoto[],
  files: IUploadFile[],
}

const ALLOWED_COUNT = 5;

const PREVIEW_SIZE = 300;

const ComponentUploadMedia = ({
  type = EUploadTarget.IMAGE,
  value: propValue = [],
  disabled = false,
  errorMessage,
  size,
  className,
  disablePreviews = false,
  height,
  limit,
  width,
  onUpdateValue = () => {
  },
}: IProps) => {
  const [{value, files}, setState] = useState<IState>({
    value: propValue.map(prop => typeof prop === 'string' ? {digest: prop} : prop),
    files: [],
  })
  const {t} = useTranslation();

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      value: propValue.map(prop => typeof prop === 'string' ? {digest: prop} : prop),
    }))
  }, [propValue])

  const ref = useRef<HTMLInputElement>(null);
  const mediaUploaded = (file: IUploadFile) => {
    files.splice(files.indexOf(file), 1);
    setState(prevState => ({...prevState, files}));
    if (file.preview) {
      URL.revokeObjectURL(file.preview);
    }
    if (file.digest) {
      value.push({digest: file.digest});
      setState(prevState => ({...prevState, value}))
      onUpdateValue(value);
    }
  }

  const fileAdded = async (file: IUploadFile) => {
    if (file) {
      if (type === EUploadTarget.IMAGE) {
        file.preview = URL.createObjectURL(file.file)
      }
      files.push(file);
      setState(prevState => ({...prevState, files}))
    }
  }
  const updateProgress = (id: string, progress: number) => {
    const file = files.find(f => f.id === id);
    if (!file) return;
    file.progress = progress;
    setState(prevState => ({...prevState, files}));
  }

  const deleteItem = (digest: string) => {
    const index = value.findIndex(photo => photo.digest === digest);
    if (index < 0) {
      console.log(`index for ${digest} not found`)
      return;
    }
    console.log(`found index ${index} for ${digest}`);
    value.splice(index, 1);
    setState(prevState => ({...prevState, value}))
    onUpdateValue(value);
  }

  const uploadError = (_message: string, file?: IUploadFile) => {
    if (!file) return;
    const f = files.find(f => f.id === file.id);
    if (f) {
      f.status = EUploadStatus.FAIL;

      setState(prevState => ({...prevState, files: [...files]}))
    }
  }

  return <div className={className}>
    <Uploader
      ref={ref}
      target={type}
      allowedCount={limit ?? ALLOWED_COUNT}
      maxSize={'100M'}
      fileUploaded={mediaUploaded}
      fileAdded={fileAdded}
      updateProgress={updateProgress}
      onError={uploadError}
    />
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    <Row className="flex row start">
      {(limit === 0 || value.length + files.length < (limit ?? ALLOWED_COUNT)) && <Cell
        count={ALLOWED_COUNT}
        hasSize={!!size}
        className="flex-item grow"
      >
        <MediaWrapper>
          <DropZoneButton
            height={height}
            width={width}
            disabled={disabled}
            className="flex centered dir-column"
            onClick={() => !disabled && ref.current?.click()}
          >
            <span className="material-icons mgb-1">upload_file</span>
            <span className="text fs-small fw-semibold">{t(`component.upload_media.upload_text.${type}`)}</span>
          </DropZoneButton>
        </MediaWrapper>
      </Cell>}
      {!disablePreviews && files.map(file => <Cell
        hasSize={!!size}
        count={ALLOWED_COUNT}
        key={file.id}
      >
        <PreviewWrapper
          error={file.status === EUploadStatus.FAIL}
        >
          <Preview
            src={file.preview}
            size={size}
            error={file.status === EUploadStatus.FAIL}
            alt={''}
          />
        </PreviewWrapper>
      </Cell>)}
      {!disablePreviews && <Gallery>
        {value.map((photo, index) => {
            const url = `${urls.REACT_APP_UPLOAD_BASE_URL}/image/${photo.digest}`;
            const thumbnail = `${url}?w=${PREVIEW_SIZE}&h=${PREVIEW_SIZE}&m=c`;
            return <Item
              key={photo.digest}
              content={<GalleryImageViewWrapper>
                <img
                  src={url}
                  alt=""
                />
                {photo.caption && <GalleryCaptionWrapper>{photo.caption}</GalleryCaptionWrapper>}
              </GalleryImageViewWrapper>}
              original={url}
              width={1000}
              height={1000}
              thumbnail={thumbnail}
            >
              {({ref, open}) => (
                <Cell
                  hasSize={!!size}
                  count={ALLOWED_COUNT}
                  key={photo.digest}
                >
                  <ThumbnailWrapper>
                    <Thumbnail
                      ref={(r) => ref.current = r as HTMLImageElement}
                      src={thumbnail}
                      size={size}
                      className={classNames({'mgr-1': index < value.length - 1})}
                      alt=""
                      onClick={open}
                    />
                    <div>{photo.caption}</div>
                    <ElementButton
                      onClick={() => deleteItem(photo.digest)}
                      size={EButtonSize.EXTRA_SMALL}
                      buttonColor={EButtonColor.ALERT}
                      icon={`delete`}
                    />
                  </ThumbnailWrapper>
                </Cell>
              )}
            </Item>
          },
        )}
      </Gallery>}
    </Row>
  </div>
}

export default ComponentUploadMedia
