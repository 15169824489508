import React                                     from "react";
import {useGetTicketByIdQuery}                   from "$services/tickets";
import {useParams}                               from "react-router";
import LayoutWithHeader                          from "$layouts/LayoutWIthHeader";
import ElementPageHeader                         from "$elements/ElementPageHeader";
import {Card, LinearProgressHolder, TableHeader} from "$elements/styled";
import ElementTable                              from "$elements/ElementTable";
import {defineRedirectLink, formatDateTime}      from "$utils/formatters";
import ElementTicketState                        from "$elements/ElementTicketState/ElementTicketState";
import {useAppSelector}                          from "../../hooks/hooks";
import {selectUser}                              from "$features/auth/authSlice";
import ElementLink                               from "$elements/ElementLink";
import ElementGallery                            from "$elements/ElementGallery";
import styled                                    from "styled-components";
import {useTranslation}                          from "react-i18next";
import classNames                                from "classnames";


const CARD_WIDTH = 1000;
const i18n_prefix = 'page.ticket.'

const DescriptionHolder = styled.div`
  max-height: 30rem;
  overflow-y: auto;
`


function formatDescription(value: string) {
  if (!value) return '';
  if (value.includes('\n')) {
    return <div className="flex dir-column">
      {value.split('\n').map((item, index) => <span key={index}>{item}</span>)}
    </div>
  }
  return <span>{value}</span>;
}

const PageTicket = () => {
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : -1;
  const user = useAppSelector(selectUser);

  const {data: ticket, isLoading} = useGetTicketByIdQuery(id);

  const header = <ElementPageHeader title={t(`${i18n_prefix}header`, {id: ticket?.id})} backLink={'/'} />

  return <LayoutWithHeader header={header}>
    <Card maxWidth={CARD_WIDTH} className="mga-4">
      <TableHeader className="flex v-centered pdx-2 pos-r">
        <div className="flex-item">{t(`${i18n_prefix}about`)}</div>
        {ticket && <ElementTicketState state={ticket.state} />}
        {isLoading && <LinearProgressHolder className={classNames('horizontal-progress', {'active': true})} />}
      </TableHeader>
      {ticket && <ElementTable disableDragScroll>
        <tbody>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_1`)}</td>
          <td>{formatDateTime(new Date(ticket.created_at))}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_2`)}</td>
          <td>{ticket.connect_at ? formatDateTime(new Date(ticket.connect_at)) : ''}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_3`)}</td>
          <td>{ticket.selected_at ? formatDateTime(new Date(ticket.selected_at as string)) : ''}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_4`)}</td>
          <td>{ticket.closed_at ? formatDateTime(new Date(ticket.closed_at)) : ''}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_5`)}</td>
          <td>{ticket.client ? `${ticket.client.name ?? ''}, ${ticket.client.phone ?? ''}` : ''}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_6`)}</td>
          <td>{ticket.address ? <span>{`${ticket?.address?.city}, ${ticket?.address?.address}`}</span> : <></>}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_7`)}</td>
          <td>
            <DescriptionHolder className="styled-scrollbar">
              {formatDescription(ticket.description)}
            </DescriptionHolder>
          </td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_8`)}</td>
          <td>{ticket.review_mark}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_9`)}</td>
          {ticket.specialist !== null && <td>
            {user?.is_admin ?
              <ElementLink blank to={defineRedirectLink(ticket.specialist.id, 'specialists')}>
                {ticket.specialist.full_name}
              </ElementLink> :
              ticket.specialist.full_name}
          </td>}
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_10`)}</td>
          <td>{ticket.comment}</td>
        </tr>
        <tr>
          <td>{t(`${i18n_prefix}rows.row_11`)}</td>
          <td><ElementGallery photos={ticket?.photos.map((digest) => ({digest})) ?? []} /></td>
        </tr>
        {user?.is_admin && <tr>
          <td>{t(`${i18n_prefix}rows.row_12`)}</td>
          <td>
            <ElementLink blank to={defineRedirectLink(ticket.id, 'tickets')}>{ticket.id}</ElementLink>
          </td>
        </tr>}
        </tbody>
      </ElementTable>}
    </Card>
  </LayoutWithHeader>
}

export default PageTicket;