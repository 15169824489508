import {ErrorMessage, Hint, InputWrapper, StyledLabel} from "$elements/styled";
import colors                                          from "$scss/_colors.module.scss";
import {IInputBaseProps}                               from "$models/IInputBasePropsWithEvents";
import IInputFlags                                     from "$models/IInputFlags";
import * as React                                      from "react";
import {useEffect, useState}                           from "react";
import styled        from "styled-components";
import EMoleculeSize from "$types/EMoleculeSize";


// <editor-fold desc="styled elements">

interface IProps extends IInputFlags {
  size?: EMoleculeSize;
  isDisabled?: boolean;
}

const Prefix = styled.div<IProps>`
  position: relative;
  color: ${({error}: IProps) => error ? colors.red : colors.darkgray};
  padding-left: 1.6rem;
  margin-right: -0.8rem;

  & :first-child {
    font-size: ${({size}: { size?: EMoleculeSize }) => size === EMoleculeSize.Extra_Small ? '1.6rem' : '2.4rem'};
  }

  ${({isDisabled, error}: IProps) => isDisabled && `
  color: ${error ? colors.redLighten3 : colors.lightgray}
  `
  };
`

const Suffix = styled.div<IProps>`
  padding-right: 1.6rem;
  color: ${({error}: IProps) => error ? colors.red : colors.darkgray};

  & :first-child {
    font-size: ${({size}: IProps) => size === EMoleculeSize.Extra_Small ? '1.6rem' : '2.4rem'};
    line-height: ${({size}: IProps) => size === EMoleculeSize.Extra_Small ? '1.6rem' : '2.4rem'};
    letter-spacing: -0.01em;
  }
`

// </editor-fold>

export default function ElementInputBase<S, T extends IInputBaseProps<S>>(Component: React.ComponentType<T>) {
  interface IState extends IInputFlags {
    errorMessage?: string | null,
  }

  return ({
    className,
    hint,
    id,
    disabled,
    label,
    prefix,
    required,
    suffix,
    value,
    errorMessage: propErrorMessage,
    size = EMoleculeSize.Medium,
    onUpdateValue = () => {
    },
    ...props
  }: T) => {
    const [{errorMessage, error}, setState] = useState<IState>({
      error:        false,
      errorMessage: propErrorMessage,
      [size]:       true,
    });

    useEffect(() => {
      setState(prevState => ({
        ...prevState,
        error:        !!propErrorMessage,
        errorMessage: propErrorMessage,
      }))
    }, [
      propErrorMessage,
    ])

    const updateValue = (newVal: S) => {
      if (value !== newVal) {
        setState(prevState => ({
          ...prevState,
          error:        false,
          errorMessage: '',
        }))
      }
      onUpdateValue(newVal);
    };

    return <div className={className}>
      {label != null &&
        <StyledLabel htmlFor={id}>{label}{required && <span className="text red">*</span>}</StyledLabel>}
      <InputWrapper
        isDisabled={disabled}
        className="flex v-centered"
        error={error}
      >
        {prefix ? <Prefix
          size={size}
          isDisabled={disabled}
          className="flex v-centered"
          error={error}
        >{prefix}</Prefix> : null}
        <div className="flex-item">
          <Component {
                       ...{
                         className,
                         hint,
                         id,
                         label,
                         prefix,
                         required,
                         disabled,
                         suffix,
                         value,
                         size,
                         error,
                         ...props,
                       } as T
                     }
                     onUpdateValue={updateValue}
          />
        </div>
        {suffix ? <Suffix
          isDisabled={disabled}
          error={error}
          size={size}
          className="flex v-centered"
        >{suffix}</Suffix> : null}
      </InputWrapper>
      {hint && <Hint isDisabled={disabled}>{hint}</Hint>}
      {errorMessage && <ErrorMessage isDisabled={disabled} className="flex start v-centered">
          <span className="material-icons outlined mgr-1">
            info
          </span>
        {errorMessage}
      </ErrorMessage>
      }
    </div>
  }
}
