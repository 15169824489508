// import {v4 as uuidv4} from "uuid";
import ElementInputBase                                      from "$elements/ElementInputBase";
import IInputBasePropsWithEvents                             from "$models/IInputBasePropsWithEvents";
import * as React                                            from 'react';
import {ChangeEvent, ReactNode, useEffect, useRef, useState} from 'react';
import {IMaskMixin}                                          from "react-imask";
import {ElementInput}                                        from '../styled';
import ElementButton                                         from "$elements/ElementButton";
import styled                                                from "styled-components";
import EButtonVariant                                        from "$types/EButtonVariant";
import EButtonSize                                           from "$types/EButtonSize";
import EButtonType                                           from "$types/EButtonType";
import colors                                                from "$scss/_colors.module.scss";
import EMoleculeSize                                         from "$types/EMoleculeSize";

interface IProps {
  suffix?: ReactNode
  iconSize?: EMoleculeSize
  clearable?: boolean
}

interface IInputHolder {
  value?: string
  clearable?: boolean
}

const Button = styled(ElementButton)<IProps>`
  color: ${colors.darkgray};
  display: ${({clearable}: IProps) => clearable === false ? 'none !important' : 'none'};
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  padding-right: ${({suffix}: IProps) => (suffix !== undefined) ? '.8rem' : '1.6rem'};

  & span {
    font-size: ${({iconSize}: IProps) => iconSize === EMoleculeSize.Extra_Small ? '1.6rem' : '2.4rem'};
  }

  &:focus {
    box-shadow: none;
    background-color: transparent;
  }

  &:hover {
    color: ${colors.darkgray};
  }
`

const InputHolder = styled.div`
  position: relative;

  &:focus-within {
    & button {
      display: ${({value, clearable}: IInputHolder) => value !== '' ? 'block' : 'none'
      || clearable === false ? 'none !important' : 'block'};
    }
  }
`

export interface IInputProps extends IInputBasePropsWithEvents<string, HTMLInputElement> {
  focus?: boolean;
  mask?: string;
  type?: string;
  alignText?: 'right' | 'center' | 'left';
}

interface IState {
  errorMessage?: string | null,
  input?: HTMLInputElement,
  rerender: number,
}

// <editor-fold desc="styled elements">
const MaskedElementInput = IMaskMixin(({inputRef, ...props}) => (
    <ElementInput
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      /* @ts-ignore */
      ref={inputRef}  // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
    />
  ))
;
// </editor-fold>

export const ElementBaseInputText = ({
  alignText = 'left',
  disabled,
  errorMessage,
  focus = false,
  readonly,
  clearable = false,
  id,
  mask,
  placeholder,
  suffix,
  size = EMoleculeSize.Medium,
  error = false,
  required = false,
  type = 'text',
  value: propValue,
  onUpdateValue,
  onChange,
  onClick = () => {
  },
  onBlur = () => {
  },
  onFocus = () => {
  },
  onKeyUp,
  inputRef,
  onKeyDown,
  onKeyPress,
}: IInputProps) => {
  const maskInput = useRef();
  const innerInputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<IState>({
    rerender: 0,
    errorMessage,
    input:    undefined,
    // [size]:   true,
  });

  const [value, setValue] = useState<string>('');
  const [clear, setClear] = useState<boolean>(false);
  const [masked, setMasked] = useState({
    value: '',
    mask:  {},
  });

  const focusInput = () => {
    setTimeout(() => {
      if (mask) {
        state.input?.focus();
      } else {
        inputRef?.current?.focus();
      }
    }, 1);
  }

  useEffect(() => {
    if (focus) {
      focusInput();
    }
  }, [])

  useEffect(() => {
    setState(prevState => ({...prevState, error: !!errorMessage, errorMessage}));
    if (focus) {
      focusInput();
    }
  }, [errorMessage, focus]);

  useEffect(() => {
    setState(prevState => ({...prevState, rerender: state.rerender + 1}));
    if (propValue) {
      if (propValue != value) {
        setValue(propValue);
      }
    } else {
      setValue('');
    }

    if (mask) {
      if (maskInput?.current) (maskInput.current as HTMLInputElement).value = propValue ?? '';
      setMasked({
        value: propValue as string,
        mask:  {},
      })
    } else {
      if (inputRef?.current) {
        inputRef.current.value = propValue ?? '';
      } else if (innerInputRef?.current) {
        innerInputRef.current.value = propValue ?? '';
      }
    }
  }, [propValue]);

  const handleResetInput = () => {
    if (clear) {
      if (onUpdateValue) {
        onUpdateValue('');
        setClear(false);
      }
      if (onUpdateValue && mask) {
        setMasked({
          value: '',
          mask:  {},
        });
        onUpdateValue('', {});
        setClear(false);
      }
    }
  }

  useEffect(() => {
    handleResetInput()
  }, [clear])

  const resetError = () => {
    setState((prevState: IState) => ({...prevState, ...{errorMessage: undefined}}));
  }

  const changed = (event: ChangeEvent<HTMLInputElement>) => {
    if (value !== event.target.value) {
      resetError();
    }
    if (typeof onUpdateValue === 'function' && value !== event.target.value) {
      setValue(event.target.value);
      onUpdateValue(event.target.value);
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  const onAccept = (value: string, mask: object, event: InputEvent) => {
    if (value !== masked.value) {
      resetError()
      if (onUpdateValue) {
        onUpdateValue(value, mask)
        setMasked({value, mask})
      }
    }
    if (typeof onChange === 'function') {
      onChange((event as unknown) as ChangeEvent<HTMLInputElement>);
    }
  }

  return mask ?
    <InputHolder
      value={masked.value}
      clearable={clearable}
    >
      <MaskedElementInput
        // @ts-ignore
        className={`flex-item align-${alignText}`}
        mask={mask}
        ref={maskInput}
        unmask
        error={error}
        required={required}
        inputSize={size}
        onAccept={onAccept}
        onClick={onClick}
        readOnly={readonly}
        onBlur={onBlur}
        onFocus={onFocus}
        inputRef={(el: HTMLInputElement) => setState(prevState => ({...prevState, input: el}))}
        value={masked.value}
        {...{id, disabled, type, placeholder, onKeyUp, onKeyDown, onKeyPress}}
      />
      <Button
        iconSize={size}
        suffix={suffix}
        variant={EButtonVariant.TEXT}
        size={EButtonSize.SMALL}
        clearable={clearable}
        type={EButtonType.RESET}
        onClick={() => setClear(true)}
        className="material-icons outlined"
      >
        cancel
      </Button>
    </InputHolder>
    :
    <InputHolder
      value={value}
      clearable={clearable}
    >
      <ElementInput
        error={error}
        className={`flex-item align-${alignText}`}
        onChange={changed}
        inputSize={size}
        required={required}
        ref={inputRef ?? innerInputRef}
        readOnly={readonly}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onWheel={(event) => {event.currentTarget.blur()}}
        value={value}
        {...{id, disabled, type, placeholder, onKeyUp, onKeyDown, onKeyPress}}
      />
      <Button
        iconSize={size}
        suffix={suffix}
        variant={EButtonVariant.TEXT}
        size={EButtonSize.SMALL}
        type={EButtonType.RESET}
        clearable={clearable}
        onClick={() => setClear(true)}
        className="material-icons outlined"
      >
        cancel
      </Button>
    </InputHolder>
}

const ElementInputText = ElementInputBase<string, IInputProps>(ElementBaseInputText);

export default ElementInputText;
