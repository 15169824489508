import * as React from "react";
import {Link}     from "react-router-dom";
import styled     from "styled-components";

interface IProps {
  to: string,
  blank?: boolean,
  className?: string,
  children: React.ReactNode
}

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export default function ElementLink({to, className = '', blank = false, children}: IProps) {
  if (blank) {
    return <a href={to} className={className} target="_blank">{children}</a>
  }

  return <StyledLink className={className} to={to}>{children}</StyledLink>
}
