import LayoutWithHeader                            from "$layouts/LayoutWIthHeader";
import React                                       from "react";
import ElementPageHeader                           from "$elements/ElementPageHeader";
import ElementTabs                                 from "$elements/ElementTabs";
import ITab                                        from "$models/ITab";
import {useTranslation}                            from "react-i18next";
import {useAppSelector}                            from "../../hooks/hooks";
import {selectCompanySlug, selectOperatorContacts} from "$features/company/companySlice";
import {HeaderH1}                                  from "$elements/styled";
import styled                                      from "styled-components";
import PersonalRoutes                              from "$routes/PersonalRoutes";


const ContactsContainer = styled.div`
  & ul {
    & li {
      list-style-type: none;
    }
  }
`

const PersonalAreaPage = () => {
  const {t} = useTranslation();
  const companySlug = useAppSelector(selectCompanySlug);
  const contacts = useAppSelector(selectOperatorContacts);
  const tabs: ITab[] = [
    {
      text:            t('page.personal_area.tabs.tab_1'),
      to:              `/personal`,
      active:          false,
      excludeSubPaths: true,
    },
    {
      text:            t('page.personal_area.tabs.tab_2'),
      to:              `/personal/managers-list`,
      active:          false,
      excludeSubPaths: true,
    },
    {
      text:   t('page.personal_area.tabs.tab_3'),
      to:     `/personal/manager-statistics`,
      active: false,
    },
  ];


  const header = <ElementPageHeader
    title={t('page.personal_area.header', {slug: companySlug})}
    controls={<ContactsContainer className="flex mgr-4 centered">
      <HeaderH1>{t('page.home.contacts')}</HeaderH1>
      <ul>
        {contacts?.map((item, index) => {
          return <li key={index} className="text fs-small">{item}</li>
        })}
      </ul>
    </ContactsContainer>}
  >
    <ElementTabs tabs={tabs} />
  </ElementPageHeader>


  return <LayoutWithHeader header={header}>
    <PersonalRoutes />
  </LayoutWithHeader>
}

export default PersonalAreaPage;