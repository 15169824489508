import IPhoto                                                      from "$models/IPhoto";
import classNames                                                  from "classnames";
import * as React                                                  from "react";
import {useTranslation}                                            from "react-i18next";
import {Gallery, Item}                                             from "react-photoswipe-gallery";
import styled                                                      from "styled-components";
import urls                                                        from "../../config/urls";
import {GalleryCaptionWrapper, GalleryImageViewWrapper, Thumbnail} from "../styled";
import colors                                                      from "$scss/_colors.module.scss";

interface IProps {
  photos: IPhoto[],
  previewSize?: number,
  previewCount?: number,
}

const MorePhotosDescription = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${colors.dodgerblueLighten1};
`

export default function ElementGallery({photos, previewSize = 32, previewCount = 3}: IProps) {
  const {t} = useTranslation();
  return <div className="flex dir-column">
    <div className="flex start">
      <Gallery>
        {photos.map((photo, index) => {
            const url = `${urls.REACT_APP_UPLOAD_BASE_URL}/image/${photo.digest}`;
            const thumbnail = `${url}?w=${previewSize}&h=${previewSize}&m=c`;
            return <Item
              key={`${photo.digest}-${index}`}
              content={<GalleryImageViewWrapper>
                <img src={url} alt="" />
                {photo.caption && <GalleryCaptionWrapper>{photo.caption}</GalleryCaptionWrapper>}
              </GalleryImageViewWrapper>}
              original={url}
              width={1000}
              height={1000}
              thumbnail={thumbnail}
            >
              {({ref, open}) => (
                <Thumbnail
                  ref={(r) => ref.current = r as HTMLImageElement}
                  src={thumbnail}
                  size={previewSize}
                  className={classNames('flex-item fixed-size', {
                    'mgr-1': index < photos.length - 1,
                    hidden:  previewCount <= index,
                  })}
                  alt=""
                  onClick={open}
                />
              )}
            </Item>
          },
        )}
      </Gallery>
    </div>
    {previewCount < photos.length &&
      <div className="flex">
        <MorePhotosDescription
          className="pdt-2 flex-item"
        >{t('element.gallery.more', {count: photos.length - previewCount})}</MorePhotosDescription>
      </div>}
  </div>
}
