import * as React                         from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import styled                             from "styled-components";
// import {v4 as uuidv4} from "uuid";
import colors                             from '$scss/_colors.module.scss';
import StyleVars                          from "$scss/_variables.module.scss";
import IInputBasePropsWithEvents          from "$models/IInputBasePropsWithEvents";
import IInputFlags                       from "$models/IInputFlags";
import {ErrorMessage, Hint, StyledLabel} from '../styled';
import EMoleculeSize                     from "$types/EMoleculeSize";


export interface IInputProps extends IInputBasePropsWithEvents<string, HTMLTextAreaElement> {
  focus?: boolean;
  mask?: string;
  type?: string;
  alignText?: 'right' | 'center' | 'left';
  width?: number,
  height?: number,
}

interface ITextareaProps extends IInputFlags {
  width?: number,
  height?: number,
}

interface IState extends IInputFlags {
  errorMessage?: string | null,
  input?: HTMLInputElement,
  rerender: number,
}

// <editor-fold desc="styled elements">

const InputWrapper = styled.div`
  border: 1px solid ${(props: ITextareaProps) => props.error ? colors.red : colors.lightgray};
  background-color: ${(props: ITextareaProps) => props.error ? colors.redLighten5 : colors.white};
  padding: 0 1.6rem;
  max-width: 100%;
  border-radius: ${StyleVars.borderRadius}
`

const ElTextarea = styled.textarea`
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  font-size: 1.6rem;
  padding: 1.6rem 0;
  background-color: transparent;
  outline: none;
  caret-color: ${(props: ITextareaProps) => props.error ? colors.red : colors.dodgerblue};
  color: ${(props: ITextareaProps) => props.error ? colors.red : colors.black};
  transition: border-color ${StyleVars.transitionDuration} ${StyleVars.transitionCurve};
  width: ${({width}: { width?: number }) => width ? `${width}px` : '100%'};
  height: ${({height}: { height?: number }) => height ? `${height}px` : 'auto'};

  &:focus {
    border-color: ${(props: IInputFlags) => props.error ? colors.red : colors.dodgerblue};
  }
`
const Prefix = styled.div`
  padding-right: 1.7rem;
  position: relative;
  color: ${(props: IInputFlags) => props.error ? colors.red : colors.black};

  &:after {
    content: '';
    width: 1px;
    height: 1.5em;
    background-color: ${(props: IInputFlags) => props.error ? colors.red : colors.black5};
    position: absolute;
    top: 0;
    right: .8rem;
  }
`

const Suffix = styled.div`
  padding-left: 8px;
`

// </editor-fold>

export default function ElementTextarea({
  alignText = 'left',
  disabled,
  errorMessage,
  focus = false,
  hint,
  id,
  label,
  placeholder,
  prefix,
  className,
  size = EMoleculeSize.Large,
  suffix,
  required = false,
  type = 'text',
  value,
  width,
  height,
  onUpdateValue,
  onChange,
  onKeyUp,
  onKeyDown,
  onKeyPress,
}: IInputProps) {

  const [state, setState] = useState<IState>({
    error:    false,
    rerender: 0,
    errorMessage,
    input:    undefined,
    [size]:   true,
  });
  const inputRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    setState(prevState => ({...prevState, error: !!errorMessage, errorMessage}));
    if (focus) {
      state.input?.focus();
    }
  }, [errorMessage, focus]);

  useEffect(() => {
    setState(prevState => ({...prevState, rerender: state.rerender + 1}))
    if (inputRef.current) {
      inputRef.current.value = value ?? '';
    }
  }, [value])

  const flags = state as IInputFlags;

  const resetError = () => {
    setState((prevState: IState) => ({...prevState, ...{error: false, errorMessage: undefined}}));
  }

  const changed = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (value !== event.target.value)
      resetError();
    if (typeof onUpdateValue === 'function') {
      onUpdateValue(event.target.value);
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  }

  return <div className={className}>
    {label != null && <StyledLabel htmlFor={id}>{label}{required && <span className="text red">*</span>}</StyledLabel>}
    <InputWrapper
      className="flex v-centered"
      error={flags.error}
    >
      {prefix ? <Prefix
        className="flex-item fixed-size"
        error={flags.error}
      >{prefix}</Prefix> : null}
      <ElTextarea
        error={flags.error}
        width={width}
        height={height}
        className={`flex-item align-${alignText}`}
        onChange={changed}
        required={required}
        ref={inputRef}
        {...{id, disabled, type, placeholder, onKeyUp, onKeyDown, onKeyPress}}
        value={value}
      />
      {suffix ? <Suffix
        className="flex-item fixed-size"
      >{suffix}</Suffix> : null}
    </InputWrapper>
    {hint && <Hint>{hint}</Hint>}
    {state.errorMessage && <ErrorMessage isDisabled={disabled} className="flex start v-centered">
          <span className="material-icons outlined mgr-1">
            info
          </span>
      {errorMessage}
    </ErrorMessage>}
  </div>
}
