import * as React     from "react";
import styled         from "styled-components";
import colors         from '$scss/_colors.module.scss';
import ElementButton  from "$elements/ElementButton";
import EButtonSize    from "$types/EButtonSize";
import EButtonVariant from "$types/EButtonVariant";
import EButtonColor   from "$types/EButtonColor";

interface IProps {
  title?: string,
  children?: React.ReactNode,
  controls?: React.ReactNode,
  loading?: boolean,
  backLink?: string,
  subtitle?: string | React.ReactNode,
}

// <editor-fold desc="styled elements">
const HeaderH1 = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.2rem;
`

const Subtitle = styled.div`
  color: ${colors.darkgray};
  font-size: 1.4rem;
`

const HeaderContainer = styled.div`
  border-bottom: 1px solid ${colors.lightgray};
`

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${colors.lightgray};
  height: 6.6rem;
`
// </editor-fold>

export default function ElementPageHeader({children, controls, backLink, subtitle, title, loading = false}: IProps) {
  return <HeaderContainer className={`horizontal-progress ${loading ? 'active' : ''}`}>
    <HeaderWrapper className="flex start v-centered">
      {backLink && <div className="flex-item fixed-size mgl-2">
        <ElementButton
          size={EButtonSize.SMALL}
          variant={EButtonVariant.TEXT}
          buttonColor={EButtonColor.INFO}
          to={backLink}
          icon="arrow_back"
        />
      </div>}
      <div className="flex-item pdx-4">
        <HeaderH1>{title}</HeaderH1>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>
      {controls && <div className="flex-item fixed-size right">{controls}</div>}
    </HeaderWrapper>
    {children && <div className="pdx-4">
      {children}
    </div>}
  </HeaderContainer>
}
