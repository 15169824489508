import {IInputBaseProps}     from "$models/IInputBasePropsWithEvents";
import {useEffect, useState} from "react";
import * as React            from "react";
import styled                from "styled-components";
import {v4 as uuidv4}        from "uuid";
import colors                from "$scss/_colors.module.scss";

interface IProps extends IInputBaseProps<string | number | undefined> {
  checked?: boolean,
  onValueChanged?: (value: string | number | undefined, selected: boolean) => void,
  checkPosition?: 'left' | 'right',
  children?: React.ReactNode
}

// <editor-fold desc="styled elements">
const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 3.2rem;
  padding-left: ${({checkPosition}: { checkPosition: 'right' | 'left' }) => checkPosition === 'right' ? '1.6rem' : '4rem'};
  padding-right: ${({checkPosition}: { checkPosition: 'right' | 'left' }) => checkPosition === 'right' ? '4rem' : '1.6rem'};
  position: relative;

  &:before {
    content: 'check_box_outline_blank';
    font-family: "Material Icons Outlined";
    font-size: 3.2rem;
    position: absolute;
    line-height: 1;
    top: 0;
    left: ${({checkPosition}: { checkPosition: 'right' | 'left' }) => checkPosition === 'right' ? 'auto' : '0'};
    right: ${({checkPosition}: { checkPosition: 'right' | 'left' }) => checkPosition === 'right' ? '0' : 'auto'};
    color: ${colors.darkgray};
  }

  &:hover {
    color: ${colors.dodgerblue};
  }
`
const CheckboxInput = styled.input`
  display: none;

  &:checked {
    & + label {
      &:before {
        content: 'check_box';
        color: ${colors.dodgerblue};
      }
    }
  }
`

// </editor-fold>

export default function ElementCheckbox({
  value,
  label,
  checked,
  disabled,
  checkPosition = 'left',
  className,
  children,
  onValueChanged = () => {
  },
}: IProps) {

  const [id] = useState(uuidv4());

  useEffect(() => {
    const el = document.getElementById(id);
    if (el) {
      (el as HTMLInputElement).checked = !!checked;
    }
  }, [checked])

  return <div className={className}>
    <CheckboxInput
      id={id}
      type="checkbox"
      disabled={disabled}
      value={value}
      defaultChecked={checked}
      onChange={(event) => {
        if (typeof checked === "undefined" || event.target.checked !== checked) {
          onValueChanged(value, event.target.checked);
        }
      }}
    />
    <CheckboxLabel
      checkPosition={checkPosition}
      htmlFor={id}
    >{children ?? label}</CheckboxLabel>
  </div>
}
