import colors                            from "$scss/_colors.module.scss";
import IGenericReference                 from "$models/IGenericReference";
import IInputBasePropsWithEvents         from "$models/IInputBasePropsWithEvents";
import * as React                        from "react";
import {useEffect, useRef, useState}     from "react";
import styled                            from "styled-components";
import {v4 as uuidv4}                    from "uuid";
import {ErrorMessage, Hint, StyledLabel} from '../styled';


interface IProps extends IInputBasePropsWithEvents<string | number, HTMLButtonElement> {
  items: IGenericReference[],
  label?: string,
  className?: string,
  hint?: string;
  errorMessage?: string | null;
  placeholder?: string,
  width?: string,
}

// <editor-fold desc="styled elements">

const StyledSelect = styled.select<{ error?: boolean, width?: string }>`
  outline: none;
  font-size: 1.6rem;
  line-height: 5.4rem;
  height: 5.4rem;
  background-color: ${({error}) => error ? colors.redLighten5 : colors.white};
  border: 1px solid ${({error}) => error ? colors.red : colors.darkgray};
  width: ${({width}) => width ? width : '100%'};
  padding: 0 1.6rem;
  border-radius: 1.2rem;
`

// </editor-fold>

interface IState {
  errorMessage?: string | null;
  selectedItems: IGenericReference[]
}

export default function ElementSelect({
  label,
  width,
  placeholder,
  items,
  hint,
  className,
  disabled,
  errorMessage: errorMessageProp,
  value,
  required,
  onUpdateValue = () => {
  },
}: IProps) {
  const [{errorMessage, selectedItems}, setState] = useState<IState>({
    errorMessage:  errorMessageProp,
    selectedItems: [],
  })
  const ref = useRef<HTMLSelectElement>(null)

  const id = uuidv4();

  useEffect(() => {
    setState(prevState => ({...prevState, errorMessage: errorMessageProp}))
  }, [errorMessageProp]);

  useEffect(() => {
    if (selectedItems) {
      if (Array.isArray(value)) {
        if (value.sort().join(',') === selectedItems.map(item => item.id).sort().join(',')) {
          return;
        }
      } else {
        if (selectedItems.length > 0 && value === selectedItems[0].id) return;
      }
    }


    if (value) {
      const values: IGenericReference[] | undefined = items.filter((item) => {
        return Array.isArray(value) ? value.includes(`${item.id}`) : item.id === value
      })
      if (values) {
        setState(prevState => ({
          ...prevState,
          selectedItems: values,
          errorMessage:  '',
        }))
      }
    }
  }, [value])

  // const triggerValueChanged = () => {
  //   onUpdateValue(selectedItems.map(item => item.id));
  // }

  const onChange = () => {
    if (ref.current)
      onUpdateValue(ref.current.value ?? '');
  }

  return <div className={className}>
    {label && <StyledLabel htmlFor={id}>{label}{required && <span className="text red">*</span>}</StyledLabel>}
    <StyledSelect
      width={width}
      error={!!errorMessage}
      ref={ref}
      //@ts-ignore
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      value={value ?? ''}
      onChange={onChange}
    >
      {placeholder && <option
        value=""
        disabled
      >{placeholder}</option>}
      {items.map(item => <option
        key={item.id}
        value={item.id}
      >{item.text}</option>)}
    </StyledSelect>
    {hint && <Hint>{hint}</Hint>}
    {errorMessage && <ErrorMessage isDisabled={disabled} className="flex start v-centered">
          <span className="material-icons outlined mgr-1">
            info
          </span>
      {errorMessage}
    </ErrorMessage>}
  </div>
}

