import * as React from "react";
import styled     from "styled-components";
import colors     from "$scss//_colors.module.scss";

interface IProps {
  children: React.ReactNode,
  header: React.ReactNode,
  disableScroll?: boolean,
}

const Container = styled.div`
  grid-column: contentLeft / contentRight;
  grid-row: contentTop / contentBottom;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-rows: [headerTop] auto [headerBottom contentTop] minmax(0, 1fr) [contentBottom];
  grid-template-columns: [left] 1fr [right];
`

const Header = styled.div`
  grid-column: left / right;
  grid-row: headerTop / headerBottom;
`

const Content = styled.div`
  grid-column: left / right;
  grid-row: contentTop / contentBottom;
  overflow: ${({disableScroll}: { disableScroll?: boolean }) => disableScroll ? 'visible' : 'auto'};
  background-color: ${colors.whitesmoke};
`

export default function LayoutWithHeader({
  header,
  children,
  disableScroll = false,
}: IProps) {
  return <Container>
    <Header>{header}</Header>
    <Content
      disableScroll={disableScroll}
      className="styled-scrollbar"
    >{children}</Content>
  </Container>
}
