import {logout}                             from "$features/auth/authSlice";
import ISidebarMenu                         from "$models/ISidebarMenu";
import colors                               from '$scss/_colors.module.scss';
import {useInitDataQuery}                   from "$services/app";
import {useLogoutMutation}                  from "$services/auth";
import EImageResizeMode                     from "$types/EImageResizeMode";
import {imageUrl}                           from "$utils/url";
import * as React                           from 'react';
import {useEffect, useState}                from 'react';
import {useTranslation}                     from "react-i18next";
import styled                               from "styled-components";
import {useAppDispatch}                     from "../../hooks/hooks";
import {Link}                               from "react-router-dom";
import ElementInputText                     from "$elements/ElementInputText";
import EMoleculeSize                        from "$types/EMoleculeSize";
import {setCompanySug, setOperatorContacts} from "$features/company/companySlice";

interface ISidebarWrapperProps {
  hasActiveMenu: boolean
}

// <editor-fold desc="styled elements">
const Wrapper = styled.div`
  height: 100vh;
  width: ${(props: ISidebarWrapperProps) => props.hasActiveMenu ? '295px' : '56px'};
  transition: width .2s linear;
  background: ${colors.black};
  color: ${colors.white};

  &.mini {
    width: 56px;
    padding: 24px 8px;

    & > .flex-item {
      display: none;
    }
  }
`

const MiniBar = styled.div`
  width: 5.6rem;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  display: grid;
  grid-template-rows: [AvatarTop] auto [AvatarBottom SwitchTop] auto [SwitchBottom ScrollableButtonsTop] 1fr [ScrollableButtonsBottom];
`

const ScrollableWrapper = styled.div`
  grid-row: ScrollableButtonsTop / ScrollableButtonsBottom;
  overflow: hidden;
`

const ScrollableMiniBar = styled.div`
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
`

const MenuContent = styled.div`
`

const Avatar = styled.img`
  border-radius: 50%;
  margin: 2rem .8rem 2.4rem;
`

const AvatarWrapper = styled.div`
  grid-row: AvatarTop / AvatarBottom;
`

const MenuAnchor = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
  color: #fff;
  outline: none;
  padding: 1.6rem;
`

const MenuButton = styled.button`
  background: transparent;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 1.6rem;
`

const SubMenu = styled.span`
  cursor: pointer;
  display: block;
  padding: .8rem 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
`

const LinkMenu = styled(Link)`
  cursor: pointer;
  display: block;
  padding: .8rem 1.6rem;
  text-decoration: none;
  color: #fff;
`

const ListHolder = styled.div`
  height: calc(100vh - 12.4rem);
  overflow-y: auto;
`

const ToggleSidebarButton = styled.button`
  background: transparent;
  display: block;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
`

// </editor-fold>


interface IState {
  active_menu: ISidebarMenu | null,
}

function Sidebar() {

  const {
    data: sidebarData,
    // isLoading,
    // isFetching,
  } = useInitDataQuery();


  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  // const user = useSelector(selectUser);
  const [state, setState] = useState<IState>({
    active_menu: null,
  })
  const [searchValue, setSearchValue] = useState('');

  const [logoutRequest, {
    isSuccess: logoutIsSuccess,
  }] = useLogoutMutation();

  useEffect(() => {
    if (logoutIsSuccess) {
      dispatch(logout());
    }
  }, [logoutIsSuccess])

  useEffect(() => {
    if (sidebarData) {
      dispatch(setOperatorContacts(sidebarData.support_contacts));
      dispatch(setCompanySug(sidebarData.company as string));
    }
  }, [sidebarData]);

  const logOut = () => {
    logoutRequest();
  }

  const menuElement = sidebarData?.menu?.map((item, index) =>
    item.to ?
      <MenuAnchor
        title={item.text}
        key={index}
        className="material-icons flex-item fixed-size"
        to={item.to}
      >{item.icon}</MenuAnchor>
      :
      <MenuButton
        title={item.text}
        key={index}
        className="material-icons flex-item fixed-size"
        onClick={() => setState(prevState => ({...prevState, active_menu: item}))}
      >
        {item.icon}
      </MenuButton>,
  );

  const handleChangeSlugClick = (slug: string) => {
    return window.location.pathname = slug;
  }

  const handleFindValueUpdate = (value: string) => {
    setSearchValue(value);
  }

  const subMenu = <>
    {state.active_menu && state.active_menu.icon !== 'info' ? <>
        <ElementInputText
          value={searchValue}
          onUpdateValue={handleFindValueUpdate}
          size={EMoleculeSize.Extra_Small}
          suffix={<span className="material-icons">search</span>}
          className="mga-2"
        />
        <ListHolder className="styled-scrollbar">
          {state.active_menu?.items?.filter(item => item.text.toLowerCase().includes(searchValue)).map((item, index) =>
            <SubMenu
              key={index}
              onClick={() => handleChangeSlugClick(item.to)}
            >{item.text}</SubMenu>)}
        </ListHolder>
      </>
      :
      <ListHolder>
        {state.active_menu?.items?.map((item, index) =>
          <LinkMenu key={index} to={item.to}>
            {item.text}
          </LinkMenu>)}
      </ListHolder>}
  </>;

  return <Wrapper
    className="flex"
    hasActiveMenu={state.active_menu != null}
  >
    <MiniBar className="flex-item fixed-size">
      <AvatarWrapper>
        <Avatar
          src={sidebarData?.user?.avatar ? imageUrl(sidebarData?.user?.avatar, 40, 40, EImageResizeMode.CROP) : '/img/avatar-placeholder.svg'}
          height="40"
          width="40"
        />
      </AvatarWrapper>
      <ScrollableWrapper>
        <ScrollableMiniBar className="flex styled-scrollbar">
          {menuElement}
          <div className="flex-item" />
          {/*<div className="flex-item fixed-size">*/}
          {/*  <MenuAnchor*/}
          {/*    to={`/user/${sidebarData?.user?.id}`}*/}
          {/*    title={t('component.sidebar.profile')}*/}
          {/*    className="material-icons flex-item fixed-size"*/}
          {/*    onClick={() => {*/}
          {/*    }}*/}
          {/*  >person</MenuAnchor>*/}
          {/*</div>*/}
          <div className="flex-item fixed-size">
            <MenuButton
              title={t('component.sidebar.logout')}
              className="material-icons flex-item fixed-size"
              onClick={logOut}
            >logout</MenuButton>
          </div>
        </ScrollableMiniBar>
      </ScrollableWrapper>
    </MiniBar>
    <MenuContent className="flex-item">
      <div className="flex pdx-2 v-centered mgb-1">
        <div className="flex-item">
          <div className="text fs-small fw-semibold white pdy-1">{state.active_menu?.text}</div>
        </div>
        <div className="flex-item fixed-size">
          <ToggleSidebarButton
            className="material-icons"
            onClick={() => setState(prevState => ({...prevState, active_menu: null}))}
          >menu_open</ToggleSidebarButton>
        </div>
      </div>
      {subMenu}
    </MenuContent>
  </Wrapper>
}


export default Sidebar;
