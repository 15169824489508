import colors         from "$scss/_colors.module.scss";
import shadows        from "$scss/_shadows.module.scss";
import StyleVars      from "$scss/_variables.module.scss";
import EButtonColor   from "$types/EButtonColor";
import EButtonSize    from "$types/EButtonSize";
import EButtonVariant from "$types/EButtonVariant";
import classNames     from "classnames";
import * as React     from "react";
import {useEffect}    from "react";
import styled         from "styled-components";
import ElementButton  from "../ElementButton";

const modalRoot = document.getElementById('modal-root');

interface IProps {
  children: React.ReactNode,
  width?: number | string,
  height?: number | string,
  persistent?: boolean,
  isOpen: boolean,
  className?: string,
  scrollable?: boolean,
  loading?: boolean,
  bodyBg?: string,
  padding?: string,
  onClosed: () => void
}

interface IModalBodyProps {
  width?: number | string,
  height?: number | string,
  padding?: string,
  scrollable?: boolean,
  bodyBackground?: string
}

// <editor-fold desc="styled elements">
const Modal = styled.div`
  position: fixed;
  display: flex;
  cursor: default;
  top: 0;
  right: 0;
  padding: 4rem;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: ${colors.lightgray5};
`

const ModalBody = styled.div`
  padding: ${({padding}: IModalBodyProps) => padding ? padding : '2.4rem'};
  //overflow-x: hidden;
  overflow-y: ${({scrollable}: IModalBodyProps) => scrollable ? 'auto' : 'visible'};
  max-height: 100%;
  position: relative;
  border-radius: ${StyleVars.borderRadius};
  background: ${({bodyBackground}: IModalBodyProps) => bodyBackground ? bodyBackground : '#fff'};
  box-shadow: ${shadows.xl};
  width: ${({width}: IModalBodyProps) => width ? (typeof width === 'number' ? `${width}px` : width) : 'auto'};
  height: ${({height}: IModalBodyProps) => height ? (typeof height === 'number' ? `${height}px` : height) : 'auto'};
`

const CloseButton = styled(ElementButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
`

// </editor-fold>

export default function ElementDialog({
  children,
  scrollable = true,
  width = 560,
  height,
  className,
  isOpen,
  loading = false,
  persistent = false,
  bodyBg,
  padding,
  onClosed = () => {
  },
}: IProps) {
  const el = document.createElement('div');

  useEffect(() => {
    modalRoot?.appendChild(el);
    return () => {
      modalRoot?.removeChild(el);
    }
  }, []);

  const close = () => {
    isOpen && onClosed();
  }

  return <Modal
    onAuxClick={(event) => event.stopPropagation()}
    onClick={(event) => {
      event.stopPropagation();
      !persistent && close()
    }}
    className={isOpen ? '' : 'hidden'}
  >
    <ModalBody
      width={width}
      height={height}
      padding={padding}
      scrollable={scrollable}
      bodyBackground={bodyBg}
      className={classNames(`styled-scrollbar horizontal-progress position-top ${className}`, {'active': loading})}
      onClick={(event) => {
        event.stopPropagation();
        document.dispatchEvent(new Event('click'));
      }}
    >
      <CloseButton
        onClick={close}
        icon={'close'}
        tabIndex={-1}
        size={EButtonSize.SMALL}
        variant={EButtonVariant.TEXT}
        buttonColor={EButtonColor.INFO}
      />
      {children}
    </ModalBody>
  </Modal>
}
