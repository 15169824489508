import * as React                                  from "react";
import {useEffect, useState}                       from "react";
import ElementButton                               from "$elements/ElementButton";
import EButtonVariant                              from "$types/EButtonVariant";
import ElementSelect                               from "$elements/ElementSelect";
import ElementDialog                               from "$elements/ElementDialog";
import ElementInputText                            from "$elements/ElementInputText";
import EButtonColor                                from "$types/EButtonColor";
import ICity                                       from "$models/ICity";
import {useGetCitiesQuery}                         from "$services/reference";
import styled                                      from "styled-components";
import {GeolocationControl, Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import colors                                      from "$scss/_colors.module.scss";
import {useTranslation}                            from "react-i18next";
import IAddress                                    from "$models/IAddress";
import IPlace                                      from "$models/IPlace";
import {removeWord}                                from "$utils/formatters";
import EButtonSize                                 from "$types/EButtonSize";

const DialogContainer = styled.div<IProps>`
  display: grid;
  grid-template-rows:  0[top] 90% [bottom] 10% [footer];
  grid-template-columns:  0 [left sidebarLeft] 45rem [sidebarRight mapLeft] auto [mapRight];
  height: 100%;
  width: 100%;
`

const Label = styled.label`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${colors.darkgray};
  font-weight: 600;
  margin-bottom: .8rem;
  display: block;
`

const SidebarContainer = styled.div`
  grid-row: top / footer;
  border-radius: 1.2rem;
  grid-column: sidebarLeft / sidebarRight;
`

const ZoomButtonsHolder = styled.div`
  width: 4rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 12px;
  position: absolute;
  top: 15.2rem;
  right: 1rem;

  & button {
    color: ${colors.black};

    &:focus {
      box-shadow: none;
      background-color: ${colors.white};
    }
  }
`

const MapContainer = styled.div`
  position: relative;
  grid-row: top / footer;
  grid-column: mapLeft / mapRight;

  & > div {
    display: grid;
    width: 100%;
    height: 100%;
  }
`

const TargetMark = styled.div`
  z-index: 1;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, 45%);
`

const SuggestContainer = styled.div`
  position: absolute;
  width: 100%;
  background: ${colors.white};
  z-index: 3;
  top: 110%;

  & div {
    color: ${colors.black};
    font-size: 1.4rem;
    font-weight: 400;
    font-family: Iter;
    line-height: 2.4rem;
    padding: .8rem 1.6rem;
  }
;

  & div:hover {
    background-color: ${colors.whitesmoke5};
    border-radius: .8rem;
  }
;
`

const IconHolder = styled.div<{ isFirstMark?: boolean }>`
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1rem;
  border: 2px solid ${({isFirstMark}) => isFirstMark === true ? `${colors.red}` : `${colors.black}`};
`

const DashedLineHolder = styled.div`
  position: absolute;
  content: '';
  width: 2.4rem;
  height: 0;
  left: -.4rem;
  top: 5.7rem;
  border-bottom: 1px dashed ${colors.darkgray};
  transform: rotate(90deg);
`

const SearchContainer = styled.div`
  position: relative;
`

const ConfirmContainer = styled.div`
  grid-row: bottom / footer;
  grid-column: sidebarLeft / sidebarRight;
`

// const DeleteButtonContainer = styled.span`
//   z-index: 2;
// `

interface ISuggest {
  displayName: string,
  hl: [number, number],
  type: string,
  value: string,
}

interface IProps {
  className?: string;
  label?: string;
  addressList?: IPlace[],
  updateAddressList: (value: IPlace[]) => void,
}

export default function ElementMap({className, label, addressList, updateAddressList}: IProps) {
  const {t} = useTranslation();
  const [map, setMap] = useState<unknown>();
  const [ymaps, setYmaps] = useState<unknown>();

  const {data: cities, isLoading: gettingCities} = useGetCitiesQuery();
  const [addresses, setAddresses] = useState<IPlace[]>([{
    text:      '',
    city_id:   -1,
    longitude: 0,
    latitude:  0,
    id:        -1,
    address:   '',
  }]);
  const [propsList, setPropsList] = useState<IPlace[]>()
  const [citySelect, setCitySelect] = useState<ICity>();
  const [placeMarks, setPlaceMarks] = useState<IPlace[]>([]);
  const [currentCity, setCurrentCity] = useState<IPlace>();
  const [suggest, setSuggest] = useState<ISuggest[]>([]);
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState<number>();
  const [coordName, setCoordName] = useState('');
  const [showMark, setShowMark] = useState(true);
  const mapState = {
    center:   currentCity ? [currentCity?.latitude, currentCity?.longitude] as number[] : [43.221313, 76.955436],
    zoom:     16,
    controls: [],
  };
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState(mapState.center);

  const handleCitySelect = (id: number) => {
    const cityById = cities && cities.find(city => city.id === id);
    setCitySelect(cityById);
    if (cityById) {
      //@ts-ignore
      ymaps.geocode(cityById.text, {provider: 'yandex#map', kind: "house", results: 1}).then((res) => {
        const city = res.geoObjects.get(0).geometry.getCoordinates();
        setCurrentCity({
          text:      cityById.text,
          address:   cityById.text,
          city_id:   cityById.id,
          latitude:  city[0],
          longitude: city[1],
        });
        //@ts-ignore
        map.setCenter(city, 16);
      });
    }
  };

  const handleAddPlaceMark = () => {
    setPlaceMarks(addresses);
    setShowMark(true);
    setAddresses([...addresses, {} as IPlace]);
  };

  const handleSuggestClick = (item: ISuggest, id: number) => {
    const value = removeWord(item.value);
    //@ts-ignore
    ymaps?.geocode(value, {provider: 'yandex#map'}).then((res) => {
      setLoading(false);
      const coords = res.geoObjects.get(0).geometry.getCoordinates();
      if (currentCity) {
        setAddresses(prevState => prevState.map((item, index) => {
          return index === id ? {
            ...item,
            text:      value,
            address:   value,
            city_id:   currentCity.city_id,
            latitude:  coords[0],
            longitude: coords[1],
            city_text: citySelect?.text,
          } : item
        }));
        setCurrentCity({
          address:   citySelect?.text as string,
          text:      citySelect?.text as string,
          city_id:   citySelect?.id as number,
          latitude:  coords[0],
          longitude: coords[1],
        });
        setPlaceMarks(prevState => prevState.map((item, index) => {
          return index === id ? {
            ...item,
            text:      value,
            address:   value,
            city_id:   currentCity.city_id,
            latitude:  coords[0],
            longitude: coords[1],
            city_text: citySelect?.text,
          } : item
        }))
        //@ts-ignore
        map.setCenter(coords, 16);
      }
    });
    setSuggest([]);
  }

  const handleAddressChange = (value: string, id: number) => {
    setAddresses(prevState => prevState.map((item, index) => {
      return index === id ? {...item, text: value, address: value} : item
    }));
    setFocus(id);
    //@ts-ignore
    ymaps.suggest(`${citySelect?.text} ${value}`).then(items => {
      setSuggest(items);
    });
    setPlaceMarks(addresses);
  };

  // const handleDeleteAddress = (id: number) => {
  //   setAddresses(prevState => prevState.filter((_f, index) => index !== id));
  //   setPlaceMarks(prevState => prevState.filter((_f, index) => index !== id));
  // };

  const handleConfirmClick = () => {
    const newAddresses = [] as IAddress[];
    addresses.map(item => {
      return newAddresses.push({
        latitude:  `${item.latitude}`,
        longitude: `${item.longitude}`,
        address:   item.text,
      })
    });
    updateAddressList(addresses);
    setPlaceMarks(addresses)
    setMapIsOpen(false);
  }

  const generalPlaceMarkLayout = () => {
    //@ts-ignore
    return ymaps?.templateLayoutFactory.createClass(
      [
        '<div class="redPlaceMark" />',
      ].join(''),
    );
  };

  const otherPlaceMarkLayout = (id: number) => {
    //@ts-ignore
    return ymaps?.templateLayoutFactory.createClass(
      [
        `<div class="blackPlaceMark">${id}</div>`,
      ].join(''),
    );
  };

  const geoLocationLayout = () => {
    //@ts-ignore
    return ymaps?.templateLayoutFactory.createClass(
      [
        '<div class="geoLocation">my_location</div>',
      ].join(''),
    );
  }

  const onUpdate = () => {
    setPropsList(addresses);
    if (propsList && updateAddressList) {
      updateAddressList(propsList);
    }
  };

  const onBoundsChange = () => {
    if (showMark) {
      //@ts-ignore
      setCenter(map.getCenter());
    }
  };

  useEffect(() => {
    if (coordName) {
      setAddresses(prevState => prevState.map((item, index) => {
        return index === addresses.length - 1 ? {...item, text: coordName, address: coordName} : item
      }));
    }
  }, [coordName]);

  useEffect(() => {
    if (center) {
      //@ts-ignore
      ymaps && ymaps.geocode(center && center).then(res => {
        const value = res.geoObjects.get(0).properties.getAll().name;
        setCoordName(value);
        setSuggest([]);
        setPlaceMarks([])
      });
      if (showMark) {
        setAddresses(prev => prev.map((item, index) => index === addresses.length - 1 ? {
            text:      coordName,
            address:   coordName,
            latitude:  center && center[0],
            longitude: center && center[1],
            city_id:   citySelect?.id as number,
            city_text: citySelect?.text,
          }
          : item));
      }
    }
  }, [center]);

  useEffect(() => {
    if (addressList && addressList?.length > 0) {
      setPlaceMarks(addressList);
      // setAddresses(addressList);
      setShowMark(false);
    }
    if (addressList && addressList?.length > 0 && cities) {
      const currentCity = cities?.find(city => city.id === addressList[0].city_id);
      setCitySelect(currentCity);
    }
    if (ymaps && citySelect) {
      //@ts-ignore
      ymaps.geocode(citySelect.text, {provider: 'yandex#map', kind: "house", results: 1}).then((res) => {
        const city = res.geoObjects.get(0).geometry.getCoordinates();
        setCurrentCity({
          text:      citySelect.text,
          address:   citySelect.text,
          city_id:   citySelect.id,
          latitude:  city[0],
          longitude: city[1],
        });
      });
    }
  }, [addressList, cities, ymaps]);

  useEffect(() => {
    if (cities && !addressList?.length) {
      setCitySelect(cities[0])
    }
  }, [cities, addressList]);

  useEffect(() => {
    //@ts-ignore
    if (map) {
      //@ts-ignore
      map.setZoom(zoom, {duration: 300});
    }
  }, [zoom]);

  return <div className={className}>
    {label && <Label>{label}</Label>}
    <ElementButton
      variant={EButtonVariant.OUTLINED}
      buttonColor={EButtonColor.INFO}
      className="flex start"
      onClick={() => setMapIsOpen(true)}
      buttonWidth={'100%'}
    >
      {addresses[0].address === "" ? t('component.map.buttons.change_address') : addresses[0].address}
    </ElementButton>
    <ElementDialog
      isOpen={mapIsOpen}
      persistent
      onClosed={() => setMapIsOpen(false)}
      width={1300}
      height={671}
      padding={"0"}
    >
      <DialogContainer
        updateAddressList={onUpdate}>
        <SidebarContainer
          className="pda-3"
        >
          <ElementSelect
            items={cities ?? []}
            value={citySelect?.id}
            className="mgb-1"
            onUpdateValue={(cityId) => handleCitySelect(+cityId)}
            loading={gettingCities}
          />
          {addresses && addresses.map((item, index) => {
            const value = addresses[index].text;

            return <SearchContainer
              key={`${index}-${item.latitude}`}
              className="flex v-centered mgb-1"
            >
              {addresses.length > 1 && <IconHolder isFirstMark={index === 0}/>}
              {addresses[index].longitude !== undefined && addresses.length > index + 1 && <DashedLineHolder/>}
              <ElementInputText
                value={value}
                clearable
                // suffix={<DeleteButtonContainer
                //   onClick={() => handleDeleteAddress(index)}
                //   className="material-icons">
                //   cancel
                // </DeleteButtonContainer>}
                className="flex-item"
                onUpdateValue={(value) => handleAddressChange(value, index)}
              />
              {focus === index && <SuggestContainer>
                {suggest.map((item, suggestIndex) => {
                  return <div
                    key={`${item.value}-${suggestIndex}`}
                    onClick={() => handleSuggestClick(item, index)}
                  >
                    {removeWord(item.value)}
                  </div>
                })}
              </SuggestContainer>}
            </SearchContainer>
          })}

          {addresses.length < 1 && <ElementButton
            loading={loading}
            disabled={loading}
            variant={EButtonVariant.TEXT}
            buttonWidth={'100%'}
            icon="add"
            onClick={handleAddPlaceMark}
            className="mgt-2"
          >
            {t('component.map.buttons.add_address')}
          </ElementButton>}

        </SidebarContainer>
        <ConfirmContainer className="pdx-3">
          <ElementButton
            buttonWidth="100%"
            variant={EButtonVariant.LIGHT}
            onClick={handleConfirmClick}
          >
            {t('component.map.buttons.confirm')}
          </ElementButton>
        </ConfirmContainer>
        <MapContainer>
          <YMaps query={{load: "package.full", apikey: '5cc109a4-e1f2-451e-a73c-2ba07a9783c3'}}>
            <div>
              <Map
                onLoad={(ymaps) => {
                  setYmaps(ymaps);
                }}
                instanceRef={map => setMap(map)}
                width={'100%'}
                height={'100%'}
                defaultState={mapState}
                modules={["templateLayoutFactory"]}
                onBoundsChange={onBoundsChange}
                options={{
                  yandexMapDisablePoiInteractivity: true,
                  suppressMapOpenBlock:             true,
                }}
              >
                {placeMarks.map((coordinate, index) => <Placemark
                  options={{
                    iconLayout: index === 0 ? generalPlaceMarkLayout() : otherPlaceMarkLayout(index),
                    iconOffset: [-10, -20],
                  }}
                  key={`${index}-${coordinate}`}
                  geometry={[coordinate.latitude !== undefined && coordinate.latitude, coordinate.longitude]}
                />)}
                <GeolocationControl options={{layout: geoLocationLayout()}}/>
              </Map>
              <ZoomButtonsHolder>
                <ElementButton
                  variant={EButtonVariant.TEXT} size={EButtonSize.SMALL} icon="add"
                  onClick={() => setZoom(zoom + 1)}/>
                <ElementButton
                  variant={EButtonVariant.TEXT} size={EButtonSize.SMALL} icon="remove"
                  onClick={() => setZoom(zoom - 1)}/>
              </ZoomButtonsHolder>
            </div>
          </YMaps>
          {showMark && <TargetMark
            className={addresses.length === 1 || addresses.length === 0 ? 'redPlaceMark' : 'blackPlaceMark'}>{addresses.length === 1 || addresses.length === 0 ? '' : addresses.length - 1}</TargetMark>}
        </MapContainer>
      </DialogContainer>
    </ElementDialog>
  </div>
}
