import {Card}     from "$elements/styled";
import * as React from "react";
import styled     from "styled-components";

interface IProps {
  children: React.ReactNode,
  filters?: React.ReactNode,
  pagination?: React.ReactNode,
}

// <editor-fold desc="styled elements">
const Wrapper = styled(Card)`
  display: grid;
  height: 100%;
  grid-template-rows: [top filtersTop] auto [filtersBottom tableTop] 1fr [tableBottom paginationTop] auto [paginationBottom];
  grid-template-columns: [left] 1fr [right];
`

const FiltersWrapper = styled.div`
  grid-row: filtersTop / filtersBottom;
  grid-column: left / right;
`

const TableWrapper = styled.div`
  position: relative;
  grid-row: tableTop / tableBottom;
  grid-column: left / right;
  width: 100%;
  overflow: hidden;
`

const PaginationWrapper = styled.div`
  grid-row: paginationTop / paginationBottom;
  grid-column: left / right;
`
// </editor-fold>

export default function LayoutTable({children, filters, pagination}: IProps) {
  return <Wrapper>
    {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
    <TableWrapper>{children}</TableWrapper>
    <PaginationWrapper>{pagination}</PaginationWrapper>
  </Wrapper>
}
