import ElementButton                                        from "$elements/ElementButton";
import ElementInputPassword                                 from "$elements/ElementInputPassword";
import ElementInputPhone                                    from "$elements/ElementInputPhone";
import {HeaderH1}                                           from "$elements/styled";
import {selectIsAuthenticated, setIsAuthenticated, setUser} from "$features/auth/authSlice";
import IError                                               from "$models/IError";
import colors                                               from "$scss/_colors.module.scss";
import ScrollbarStyle                                       from "$scss/_scrollbar.module.scss";
import StyleVars                                            from "$scss/_variables.module.scss";
import {useLoginMutation}                                   from "$services/auth";
import * as React                                           from "react";
import {KeyboardEvent, useEffect, useState}                 from "react";
import {useTranslation}                                     from "react-i18next";
import {useSelector}                                        from "react-redux";
import {useNavigate}                                        from "react-router-dom";
import styled                                               from "styled-components";
import {useAppDispatch}                                     from "../../hooks/hooks";
// import {selectIsAuthenticated}                         from "../../features/auth/authSlice";

// <editor-fold desc="styled elements">
const PageWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: ${colors.lightgray};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 400px;
  min-width: 320px;
  overflow: auto;

@include ${ScrollbarStyle.light};
`

const PageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 560px;
  max-height: 310px;
  transform: translate3d(-50%, -50%, 0);
`

const FormWrapper = styled.form`
  border-radius: ${StyleVars.borderRadius};
  background-color: ${colors.white};
  padding: 4rem;
`

const Copyright = styled.div`
  font-size: 1.2rem;
  color: ${colors.darkgray};
  text-align: center;
`

// </editor-fold>

// const selectIsAuthenticated = false;

export default function PageLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  // const isAuthenticated =false;// useSelector(selectIsAuthenticated);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<IError>({});
  const [focusPassword, setFocusPassword] = useState(false);

  const [login, {
    data:      loginResponse,
    isLoading: loginIsLoading,
    // reset:           loginResetState,
  }] = useLoginMutation();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const url = new URL(location.href);
      let redirect = url.searchParams.get('redirect');
      if (redirect && redirect.length > 0) {
        redirect = decodeURIComponent(redirect);
      }
      navigate(redirect ? redirect : history.state?.from?.pathname ?? '/');
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (loginResponse?.data?.status && loginResponse?.token) {
      dispatch(setIsAuthenticated(loginResponse.token));
      if (loginResponse.data.content)
        dispatch(setUser(loginResponse.data.content))
    }
  }, [loginResponse])

  const requestLogin = () => {
    let hasError = false;
    if (!phone || phone.length < 10) {
      setErrors(
        prevState => ({
          ...prevState,
          phone: t('page.login.empty_field'),
        }),
      )
      hasError = true;
    }
    if (!password) {
      setErrors(prevState => ({
        ...prevState,
        password: t('page.login.empty_field'),
      }))
    }
    if (hasError) return;

    login({phone, password})
  }

  const onKeyPressPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      requestLogin();
    }
  }

  const updatePhone = (value: string | null | undefined) => setPhone(value ?? '');

  const onKeyPressPhone = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setFocusPassword(true);
    }
  }

  return <PageWrapper>
    <PageContainer>
      <FormWrapper className="mgb-5">
        <HeaderH1 className="mgb-3">{t('page.login.authorization')}</HeaderH1>
        <ElementInputPhone
          placeholder="(000) 000-00-00"
          label={t('page.login.labels.phone')}
          className="mgb-3"
          disabled={loginIsLoading}
          required
          value={phone ?? ''}
          errorMessage={loginResponse?.data?.errors?.phone ?? errors.phone}
          onUpdateValue={updatePhone}
          onKeyPress={onKeyPressPhone}
        />
        <ElementInputPassword
          placeholder={t('page.login.placeholders.password')}
          label={t('page.login.labels.password')}
          className="mgb-3"
          onKeyPress={onKeyPressPassword}
          focus={focusPassword}
          onUpdateValue={(value) => {
            setPassword(value ?? '');
          }}
          required
        />
        <ElementButton
          loading={loginIsLoading}
          buttonWidth={'100%'}
          disabled={loginIsLoading}
          onClick={() => requestLogin()}
        >{t('page.login.buttons.login')}</ElementButton>
      </FormWrapper>
      <Copyright>© Naimi.kz 2015 — 2022</Copyright>
    </PageContainer>
  </PageWrapper>
}
