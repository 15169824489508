import ElementCheckbox       from "$elements/ElementCheckbox";
import {formatMoney}         from "$utils/formatters";
import ElementButton         from "$elements/ElementButton";
import EButtonColor          from "$types/EButtonColor";
import EButtonSize           from "$types/EButtonSize";
import EButtonVariant        from "$types/EButtonVariant";
import EMoleculeSize         from "$types/EMoleculeSize";
import * as React            from "react";
import {useEffect, useState} from "react";
import styled                from "styled-components";
import {Card}                from "$elements/styled";
import ElementInputText      from "$elements/ElementInputText";
import colors                from "$scss/_colors.module.scss";
import {useTranslation}      from "react-i18next";
import {IResponseGoodItem}   from "$models/IServiceOption";
import {IGoodItemWithPrice}  from "$pages/PageHome/Subs/NewTicket";


// <editor-fold desc="styled elements">
const GoodsCard = styled(Card)`
  max-height: 30rem;
  overflow-y: auto;
`

const StyledInput = styled(ElementInputText)`
  input {
    text-align: center;
    width: 5rem;
  }
`

const GoodItem = styled.div`
  color: ${({isDisabled}: { isDisabled: boolean }) => isDisabled ? `${colors.darkgray}` : ''};

  span:nth-child(2) {
    color: ${({isDisabled}: { isDisabled: boolean }) => isDisabled ? `${colors.darkgray}` : `${colors.dodgerblue}`};
  }
`

//</editor-fold>

interface IProps {
  items?: IResponseGoodItem[],
  optionId: string,
  onSetGoodsIds: (id: number, selected: boolean, item: IGoodItemWithPrice, optionId: string, optionType: string) => void,
  onCountChange?: (count: number, itemId: number) => void,
  optionType: string,
  disabled: boolean,
  currentGoods: number[],
}

const ElementServiceOptionItems = ({
  items,
  optionId,
  disabled,
  onSetGoodsIds,
  onCountChange,
  optionType,
  currentGoods,
}: IProps) => {
  const {t} = useTranslation();

  const [currentItems, setCurrentItems] = useState(items?.map(item => ({...item, count: 1})));

  const handleChangeCountForOneStepClick = (id: number, count: number, isIncrement: boolean) => {
    if (isIncrement) {
      setCurrentItems(prev => prev?.map(item => item.id === id ? {...item, count: count + 1} : item));
      onCountChange && onCountChange(count + 1, id);
    } else {
      setCurrentItems(prev => prev?.map(item => item.id === id ? {...item, count: count - 1} : item));
      onCountChange && onCountChange(count - 1, id);
    }
  }

  const handleSetGoods = (value: number, selected: boolean, item: IGoodItemWithPrice) => {
    onSetGoodsIds(value as number, selected, item, optionId, optionType);
  }

  const handleSetCount = (id: number, value: string | number) => {
    if (!currentItems) return
    const updatedItems = currentItems.map(item => (item.id === id ? {...item, count: +value} : item));
    setCurrentItems(updatedItems);
    onCountChange && onCountChange(+value, id);
  }

  const handleOnBlur = (id: number, value: string | number) => {
    if (value === 0 || !value) {
      setCurrentItems(prev => prev?.map(item => item.id === id ? {...item, count: 1} : item));
      onCountChange && onCountChange(1, id);
    }
  }

  useEffect(() => {
    setCurrentItems(prev => prev?.map(item => !currentGoods.includes(item.id) ? {...item, count: 1} : item));
  }, [currentGoods]);

  return <GoodsCard className="pda-2">
    <span className="text fw-semibold">{t('page.home.newTicket.goods')}</span>
    {currentItems?.map((item) => <div key={item.id} className="mgt-2 flex v-centered">
        <ElementCheckbox
          checked={currentGoods.includes(item.id)}
          disabled={disabled}
          value={item.id}
          onValueChanged={(value, selected) => handleSetGoods(value as number, selected, {
            value: item.count,
            id:    item.id,
            price: item.price,
          })}
        >
          <GoodItem isDisabled={disabled} className="flex v-centered">
            <span>{item.title}</span>
            <span className="mgl-1">
              {item.price ? formatMoney(item.price) : ''}
            </span>
          </GoodItem>
        </ElementCheckbox>
        <div className="flex start v-centered">
          <span className={disabled ? 'text darkgray' : ""}>{t('page.home.newTicket.goods_count')}</span>
          <ElementButton
            buttonColor={EButtonColor.INFO}
            size={EButtonSize.EXTRA_SMALL}
            variant={EButtonVariant.OUTLINED}
            onClick={() => handleChangeCountForOneStepClick(item.id, item.count, false)}
            disabled={item.count <= 1 || !currentGoods.includes(item.id)}
            icon="remove"
            className="mgl-2"
          />
          <StyledInput
            className="mgx-1 text-center"
            type="number"
            value={`${item.count}`}
            disabled={!currentGoods.includes(item.id)}
            onUpdateValue={value => handleSetCount(item.id, value ? parseInt(value) : 0)}
            onBlur={() => handleOnBlur(item.id, item.count)}
            size={EMoleculeSize.Extra_Small}
          />
          <ElementButton
            buttonColor={EButtonColor.INFO}
            size={EButtonSize.EXTRA_SMALL}
            variant={EButtonVariant.OUTLINED}
            disabled={!currentGoods.includes(item.id)}
            onClick={() => handleChangeCountForOneStepClick(item.id, item.count, true)}
            icon="add"
          />
        </div>
      </div>,
    )}

  </GoodsCard>
}

export default ElementServiceOptionItems;