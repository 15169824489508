import colors                from '$scss/_colors.module.scss';
import scssVars              from '$scss/_variables.module.scss';
import ETabType              from "$types/ETabType";
import ITab                  from "$models/ITab";
import {companySlug}         from "$utils/url";
import classNames            from "classnames";
import * as React            from "react";
import {useEffect, useState} from "react";
import styled                from "styled-components";
import ElementLink           from "../ElementLink";


interface IProps {
  tabs: ITab[],
  type?: ETabType,
}

const Tab = styled(ElementLink)`
  color: ${({active}: { active?: boolean }) => active ? colors.dodgerblue : colors.black};
  font-size: 1.4rem;
  font-weight: 600;
  display: block;
  text-decoration: none;
  padding: 1.6rem 0;
  position: relative;

  &:after {
    content: '';
    opacity: ${({active}: { active?: boolean }) => active ? 1 : 0};
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    background: ${colors.dodgerblue};
  }
`

const Tabs = styled.div`
  &.tabs--pill {
    border-radius: .4rem;
    padding: .4rem .8rem;
    background-color: ${colors.darkgray};

    a {
      border-radius: .2rem;
      background-color: transparent;
      padding: .8rem 2.4rem;
      color: #fff;
      transition: background-color ${scssVars.transitionDuration} ${scssVars.transitionCurve}, color ${scssVars.transitionDuration} ${scssVars.transitionCurve};

      &:after {
        content: none
      }

      &.tab--active {
        color: ${colors.dodgerblue};
        background-color: #fff;
      }
    }
  }
`

interface IState {
  tabs: ITab[],
}

export default function ElementTabs({
  tabs,
  type = ETabType.DEFAULT,
}: IProps) {

  const [state, setState] = useState<IState>({tabs: tabs});

  useEffect(() => {
    const iTabs = tabs;
    iTabs.forEach((tab) => {
      const path = window.location.pathname;
      tab.active =
        path === `/${companySlug}${tab.to}` || window.location.href === `/${companySlug}${tab.to}` ||
        (!tab.excludeSubPaths &&
          (path.indexOf(`/${companySlug}${tab.to}`) === 0 || window.location.href.indexOf(`/${companySlug}${tab.to}`) === 0)
        );
    })

    setState(prevState => ({...prevState, tabs: iTabs}))
  }, [tabs]);

  return <div className="flex start">
    <Tabs className={classNames("flex start flex-item fixed-size", {'tabs--pill': type === ETabType.PILLS})}>
      {state.tabs?.map((tab, index) => <div
        key={tab.to}
        className="flex-item fixed-size"
      >
        <Tab
          to={tab.to}
          blank={tab.blank}
          active={tab.active}
          className={classNames({'mgr-4': index < state.tabs?.length - 1, 'tab--active': tab.active})}
        >{tab.text}</Tab>
      </div>)}
    </Tabs>
  </div>
}
